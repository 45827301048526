import * as Sentry from '@sentry/react';
import LogRocketConnection from 'logrocket';

import config from '@/config';
import { urls } from '@/constants';
import { Plan, Region, UserProfile } from '@/types';

import { isData, isError, isString } from './utils';

export const initLogger = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tunnel: urls.actions.sentry,
  });
};

interface InitProps {
  userId?: string;
  profile?: UserProfile;
  plan?: Plan;
  region?: Region;
  registrationState?: boolean;
}

export const initSentry = ({
  userId,
  profile,
  plan,
  registrationState,
  region,
}: InitProps) => {
  Sentry.setUser({ id: userId });

  if (!profile || !plan) return;

  Sentry.setUser({ id: userId, email: profile.email });
  Sentry.setContext('plan', plan);
  Sentry.setContext('user info', {
    region,
    registrationState,
    ...profile,
  });
};

export const initLogRocket = ({ userId, profile, region }: InitProps) => {
  LogRocketConnection.init(config.LOGROCKET_PROJECT_KEY, {
    serverURL: urls.actions.logrocket,
  });

  const logRocketData = {
    name: profile ? `${profile.firstName} ${profile.lastName}` : '-',
    email: profile?.email || '-',
    isNasba: region === 'USA',
    isAnonymous: !userId,
  };
  if (userId) {
    LogRocketConnection.identify(userId, logRocketData);
  } else {
    LogRocketConnection.identify(logRocketData);
  }

  LogRocketConnection.getSessionURL(sessionURL => {
    Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
  });
};

export const log = (...events: any[]) => {
  try {
    const messages = events.filter(isString);
    const objects = events.filter(isData);
    const errors = events.filter(isError);

    // breadcrumbs must be logged before exception is captured
    messages.forEach(m => logBreadcrumb({ message: m }));
    objects.forEach(o => logBreadcrumb({ data: o }));
    errors.forEach(e => Sentry.captureException(e));

    // fallback: if Sentry.captureException is never called, the breadcrumbs don't get logged
    if (errors.length === 0) {
      Sentry.captureException('No other Error was provided');
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const logBreadcrumb = ({
  message,
  data,
}: {
  message?: string;
  data?: Record<string, any>;
}) => {
  try {
    const payload = {
      message,
      level: 'debug' as Sentry.SeverityLevel,
      data,
    };

    Sentry.addBreadcrumb(payload);
  } catch (error) {
    Sentry.captureException(error);
  }
};
