import * as R from 'ramda';

import { fetchChapterProgress } from '@/requests';
import { logBreadcrumb } from '@/services';
import { selectUserRegion } from '@/store/user-api';
import { Chapter, UserChapter, UserChapterProgress } from '@/types';

import { chaptersApi } from '../chapters-api';
import { nodeApi } from '../node-api';
import { RootState } from '../store';
import { NodeApiTags } from '../store.constants';
import { rtkQueryError } from '../store.utils';
import { userQuizzesApi } from '../user-quizzes-api';
import { getChapterStatus } from './user-chapters.util';

export const userChaptersApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    fetchChapterProgress: builder.query<UserChapter[], { episodeId: string }>({
      query: fetchChapterProgress,
      transformResponse: (response: any) => response.data,
      providesTags: (result, error, { episodeId }) => [
        NodeApiTags.USER_CHAPTER_PROGRESS,
        { type: NodeApiTags.USER_CHAPTER_PROGRESS, id: episodeId },
      ],
    }),
  }),
});

export const userEpisodeChapterProgressApi = userChaptersApi.injectEndpoints({
  endpoints: builder => ({
    fetchEpisodeChapterProgress: builder.query<
      UserChapterProgress[],
      { episodeId: string }
    >({
      queryFn: async ({ episodeId }, { dispatch, getState }) => {
        try {
          const [userChapters, userQuizzes, chapters] = await Promise.all([
            dispatch(
              userChaptersApi.endpoints.fetchChapterProgress.initiate(
                { episodeId },
                { forceRefetch: true, subscribe: false },
              ),
            ).unwrap(),
            dispatch(
              userQuizzesApi.endpoints.fetchUserQuizzes.initiate(null, {
                forceRefetch: true,
                subscribe: false,
              }),
            ).unwrap(),
            dispatch(
              chaptersApi.endpoints.fetchEpisodeChapters.initiate(
                {
                  episodeId,
                  region: selectUserRegion(getState() as RootState),
                },
                { subscribe: false },
              ),
            ).unwrap(),
          ]);

          const userQuizzesById = R.groupBy(R.prop('chapterId'), userQuizzes);
          const userChaptersById = R.indexBy(R.prop('id'), userChapters);

          const chapterStatuses = R.pipe(
            R.filter((c: Chapter) => !c.isTrailer && !c.isArchived),
            R.sortBy(R.prop('chapterPosition')),
            R.map(chapter => {
              const { chapterId } = chapter;
              const userChapter = userChaptersById[chapterId];
              const userQuiz = userQuizzesById[chapterId];
              const status = getChapterStatus({
                chapter,
                userChapter,
                userQuiz,
              });
              return {
                ...userChapter,
                chapterId,
                status,
                isVerifiable: chapter.isVerifiable,
                isTrailer: chapter.isTrailer,
              };
            }),
          )(chapters);

          if (chapterStatuses === undefined) {
            logBreadcrumb({
              message: 'chapterStatuses should never be undefined',
              data: {
                userQuizzes: JSON.stringify(userQuizzes),
                userChapters: JSON.stringify(userChapters),
              },
            });
          }

          return {
            data: chapterStatuses,
          };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      providesTags: (result, error, { episodeId }) => [
        NodeApiTags.USER_CHAPTER_PROGRESS,
        { type: NodeApiTags.USER_CHAPTER_PROGRESS, id: episodeId },
      ],
    }),
  }),
});

export const { useFetchEpisodeChapterProgressQuery } =
  userEpisodeChapterProgressApi;
