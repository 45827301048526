import Cookies from 'js-cookie'; // TODO this was upgraded from 2 to 3
import { jwtDecode } from 'jwt-decode'; // TODO this upgraded from 2 to 4

import config from '@/config';
import { urls } from '@/constants';
import { getRedirectUrl } from '@/utils';

const { APP_DOMAIN: domain } = config;

const getUser = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return null;
  const decodedToken = jwtDecode(accessToken);
  return decodedToken.user;
};

export const getAccessToken = () => Cookies.get('access_token', { domain });

export const getRefreshToken = () => Cookies.get('refresh_token', { domain });

export const removeAccessToken = () =>
  Cookies.remove('access_token', { domain });

export const removeRefreshToken = () =>
  Cookies.remove('refresh_token', { domain });

export const setAccessToken = accessToken =>
  Cookies.set('access_token', accessToken, {
    domain,
    expires: 365,
  });

export const setRefreshToken = refreshToken =>
  Cookies.set('refresh_token', refreshToken, {
    domain,
    expires: 365,
  });

export const getAccessTokenUserId = () => {
  const user = getUser();
  return user?.id;
};

export const login = () => {
  window.open(getRedirectUrl(urls.accounts.login), '_self');
};

export const logout = () => {
  removeAccessToken();
  removeRefreshToken();
  window.location.replace(urls.lumiq);
};
