import { Visibility } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';

export const TrackerFooter = () => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.container}>
      <Button
        onClick={() => navigate(Routes.CPD_REQUIREMENTS)}
        label="CPA Guidance"
        variant="secondary"
        startIcon={<Visibility />}
        fullWidth={false}
      />
      <Typography variant="select" sx={styles.text}>
        The purpose of the LumiQ CPD Tracker is to keep track of the number of
        verifiable hours you have completed each year. It is each CPAs
        responsibility to refer to the official CPA guidance or contact your
        specific regulatory body to ensure you are in compliance.
      </Typography>
    </Box>
  );
};

const styles = {
  container: theme => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
  text: theme => ({
    color: 'blueBlack',
    ml: 2,
    [theme.breakpoints.down('sm')]: {
      ml: 0,
      mt: 2,
    },
  }),
};
