import * as Sentry from '@sentry/react';
import * as R from 'ramda';

import { Modals, PlanTypes } from '@/constants';
import {
  fetchIsProductFeedbackReady,
  fetchUserRegionRequest,
  PostgrestEndpoints,
  submitProductFeedback,
  updateUserDesignationsRequest,
  updateUserRegionRequest,
} from '@/requests';
import { AnalyticsService, EVENTS, log } from '@/services';
import { Plan, Region, UserDesignation, UserProfile } from '@/types';
import { camelCaseKeys } from '@/utils';

import { updateRegion } from '../app-settings';
import { nodeApi } from '../node-api';
import { onboardingApiPostgrest } from '../onboarding-api';
import { postgrestApi } from '../postgrest-api';
import { RootState } from '../store';
import { NodeApiTags, PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError, rtkQueryError } from '../store.utils';
import { uiActions } from '../ui';
import { transformDesignationsFormForApi } from './user-api.utils';

type UpdateUserDesignationsPayload = {
  userCountry: string[];
  userDesignations: Partial<
    Record<
      UserDesignation['countryCode'],
      Partial<Record<UserDesignation['designation'], unknown>>
    >
  >;
};

type UpdateUserProfilePayload = {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle?: string;
  companyName?: string;
  event?: Record<'INTENT' | 'SUCCESS' | 'FAIL', string>;
};

type UpdatePasswordPayload = {
  oldPassword: string;
  newPassword: string;
  email: string;
};

export const userApiPostgrest = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchUserProfile: builder.query<UserProfile, void>({
      query: () => ({
        url: PostgrestEndpoints.PROFILE,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return {
          firstName: response[0]?.first_name,
          lastName: response[0]?.last_name,
          email: response[0]?.email,
          onboardingCompletedAt: response[0]?.onboarding_completed_at,
          userCreatedAt: response[0]?.user_created_at,
          jobTitle: response[0]?.job_title,
          companyName: response[0]?.company_name,
        };
      },
      providesTags: [PostgrestApiTags.USER_PROFILE],
    }),
    fetchUserRegistrationState: builder.query<boolean, void>({
      query: () => ({
        url: PostgrestEndpoints.USER_REGISTRATION_STATE,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: isRegistrationCompleted } = await queryFulfilled;
          Sentry.setContext('onboarding', { isRegistrationCompleted });
          if (isRegistrationCompleted) return;
          dispatch(uiActions.setActiveModal({ name: Modals.ONBOARDING }));
          AnalyticsService.logEvent(EVENTS.ONBOARDING_EVENTS.START);
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (response: any) =>
        !!response[0]?.registration_completed,
      providesTags: [PostgrestApiTags.USER_REGISTRATION_STATE],
    }),
    updateUserDesignations: builder.mutation<
      UserDesignation[],
      UpdateUserDesignationsPayload
    >({
      queryFn: async (
        { userCountry, userDesignations },
        { getState },
        _,
        baseQuery,
      ) => {
        try {
          const { data: designationOptions } =
            onboardingApiPostgrest.endpoints.fetchDesignationOptions.select(
              undefined,
            )(getState() as RootState);

          const payload = transformDesignationsFormForApi({
            userCountry,
            userDesignations,
            designationOptions,
          });

          const { data: designationResults, ...result } = await baseQuery(
            updateUserDesignationsRequest({ userDesignations: payload }),
          );

          return {
            data: camelCaseKeys(designationResults),
            ...result,
          };
        } catch (error) {
          return rtkQueryError(error);
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(
            EVENTS.ONBOARDING_EVENTS.UPDATE_DESIGNATION.INTENT,
          );
          await queryFulfilled;
          AnalyticsService.logEvent(
            EVENTS.ONBOARDING_EVENTS.UPDATE_DESIGNATION.SUCCESS,
          );
        } catch (error: any) {
          AnalyticsService.logEvent(
            EVENTS.ONBOARDING_EVENTS.UPDATE_DESIGNATION.FAIL,
            {
              error: error?.message,
            },
          );
          handleOnQueryStartedError(error);
        }
      },
    }),
    fetchPlan: builder.query<Plan, void>({
      query: () => ({
        url: PostgrestEndpoints.PLAN,
      }),
      transformResponse: (response: any) => {
        try {
          const [plan] = response;
          if (!plan) return plan;
          return R.pipe(camelCaseKeys, plan => ({
            ...plan,
            planType: plan.freeTrial
              ? PlanTypes.FREE_TRIAL
              : plan.validUntil
                ? PlanTypes.TIME
                : PlanTypes.USAGE,
            creditsRemaining:
              plan.usageLimit &&
              Math.max(
                Math.round((plan.usageLimit - plan.creditsUsed) * 10) / 10,
                0,
              ),
          }))(plan);
        } catch (error) {
          log(new Error('Error transforming fetched plan', { cause: error }));
        }
      },
      providesTags: [PostgrestApiTags.PLAN],
    }),
    updateUserProfile: builder.mutation<void, UpdateUserProfilePayload>({
      query: ({ firstName, lastName, email, jobTitle, companyName }) => ({
        method: 'POST',
        url: PostgrestEndpoints.UPDATE_PROFILE,
        body: {
          first_name: firstName,
          last_name: lastName,
          job_title: jobTitle || null,
          company_name: companyName || null,
          email,
        },
      }),
      onQueryStarted: async ({ event }, { dispatch, queryFulfilled }) => {
        if (!event) return;

        try {
          AnalyticsService.logEvent(event.INTENT);
          await queryFulfilled;
          AnalyticsService.logEvent(event.SUCCESS);
        } catch (error: any) {
          AnalyticsService.logEvent(event.FAIL, {
            error: error?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
      invalidatesTags: [PostgrestApiTags.USER_PROFILE],
    }),
    fetchUserTags: builder.query({
      query: () => ({
        url: PostgrestEndpoints.USER_TAGS,
      }),
      transformResponse: (response: any) => response.tags,
      providesTags: [PostgrestApiTags.USER_TAGS],
    }),
    updatePassword: builder.mutation<void, UpdatePasswordPayload>({
      query: ({ oldPassword, newPassword, email }) => ({
        method: 'POST',
        url: PostgrestEndpoints.UPDATE_PASSWORD,
        body: {
          email,
          old_password: oldPassword,
          new_password: newPassword,
        },
      }),
    }),
    linkPlanToUser: builder.mutation<void, { inviteToken: string }>({
      query: ({ inviteToken }) => ({
        method: 'POST',
        url: PostgrestEndpoints.LINK_PLAN,
        body: { invite_token: inviteToken },
      }),
      invalidatesTags: [PostgrestApiTags.PLAN, PostgrestApiTags.USER_TAGS],
    }),
  }),
});

export const userApiNode = nodeApi.injectEndpoints({
  endpoints: builder => ({
    fetchUserRegion: builder.query<Region, void>({
      query: fetchUserRegionRequest,
      transformResponse: (response: any) => response.data.region,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: region } = await queryFulfilled;
          dispatch(updateRegion({ region }));
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
      providesTags: [NodeApiTags.USER_REGION],
    }),
    updateUserRegion: builder.mutation({
      query: updateUserRegionRequest,
      invalidatesTags: [NodeApiTags.USER_REGION],
    }),
    showProductFeedbackIfReady: builder.mutation({
      query: fetchIsProductFeedbackReady,
      transformResponse: (res: any) => res.data,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: isProductFeedbackReady } = await queryFulfilled;
          if (isProductFeedbackReady) {
            dispatch(
              uiActions.setActiveModal({ name: Modals.PRODUCT_FEEDBACK }),
            );
          }
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    submitProductFeedback: builder.mutation({
      query: submitProductFeedback,
    }),
  }),
});

const {
  useFetchUserProfileQuery,
  useUpdateUserProfileMutation,
  useFetchUserRegistrationStateQuery,
  useFetchPlanQuery,
  useUpdateUserDesignationsMutation,
  useFetchUserTagsQuery,
  useUpdatePasswordMutation,
  useLinkPlanToUserMutation,
} = userApiPostgrest;

const {
  useFetchUserRegionQuery,
  useSubmitProductFeedbackMutation,
  useShowProductFeedbackIfReadyMutation,
} = userApiNode;

export {
  useFetchPlanQuery,
  useFetchUserProfileQuery,
  useFetchUserRegionQuery,
  useFetchUserRegistrationStateQuery,
  useFetchUserTagsQuery,
  useLinkPlanToUserMutation,
  useShowProductFeedbackIfReadyMutation,
  useSubmitProductFeedbackMutation,
  useUpdatePasswordMutation,
  useUpdateUserDesignationsMutation,
  useUpdateUserProfileMutation,
};
