import { Box, Typography, useMediaQuery } from '@mui/material';

import { convertSecondsToMinutes } from '@/utils';

import { Resources } from '../../Resources';
import { ChapterQuiz } from './ChapterQuiz';

export const ChapterDescription = ({
  chapter,
  chapterIndex,
  maxChapterIndex,
  quizzes,
  onQuizSuccess,
  onSelectChapter,
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    !!chapter && (
      <Box mt={isMobile ? 4 : 12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}>
          <Typography variant="h1" sx={{ fontWeight: 600 }}>
            Chapter {chapterIndex + 1}
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 400 }}>
            &nbsp;/{convertSecondsToMinutes(chapter.duration)} mins
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={{ color: 'blueBlack', fontWeight: 'bold', mt: 2 }}>
          {chapter.name}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4, color: 'blueBlack' }}>
          {chapter.description}
        </Typography>
        {chapter.resources?.length > 0 && (
          <Resources resources={chapter.resources} chapter={chapter} />
        )}
        {quizzes?.length > 0 && (
          <Box mt={4}>
            <ChapterQuiz
              chapter={chapter}
              chapterIndex={chapterIndex}
              maxChapterIndex={maxChapterIndex}
              quizzes={quizzes}
              onQuizSuccess={onQuizSuccess}
              onSelectChapter={onSelectChapter}
            />
          </Box>
        )}
      </Box>
    )
  );
};
