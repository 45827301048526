import { createTheme } from '@mui/material/styles';

import {
  FONT_SIZE,
  HTML_FONT_SIZE,
  MobileTypography,
  Typography,
  TypographyVariant,
} from './typography';

export const PALETTE = {
  aqua: '#00F2FF',
  backdrop: 'rgba(46,56,77,0.8)',
  black: '#000000',
  blue: '#1760A9',
  blue50: '#135A93',
  blue400: '#3D91DB',
  blue500: '#1C79C4',
  blueBlack: '#2E384D',
  blueBlackLight: '#BFC5D2',
  blueBlackTransparent: 'rgba(46,56,77,0.2)',
  blueDark: '#2D2D3D',
  blueLight: '#DCE7F2',
  bluePrimary: '#3784C3',
  brand300: '#9ACEFB',
  beige: '#FDF1E5',
  brown: '#A95A0C',
  gold: '#EFDC52',
  gray: '#69707F',
  grayBright: '#8798AD',
  grayBrightDark: '#0000003b',
  grayBrightMid: '#EAECEF',
  grayBrightLight: '#EEF3F5',
  grayLight: '#F4F6FC',
  grayMid: '#D5D7DA',
  grayNeutral: '#F4F4F4',
  grayNeutralLight: '#FAFAFA',
  grayDark: '#898F9B',
  green: '#159553',
  greenLight: '#DCEFE5',
  greenDark: '#169333',
  magenta: '#D82A95',
  neutral20: '#ECEFF5',
  neutral35: '#ACB0B9',
  neutral50: '#21242C',
  neutral500: '#414A4C',
  orange: '#DF8020',
  red: '#D63649',
  redDark: '#D16161',
  redDarkLight: '#F5E4E4',
  redLight: '#F9E1E4',
  teal: '#3DBDC4',
  tealBright: '#7AD9DE',
  tealLight: '#D8F2F3',
  tealTransparent: 'rgba(61,189,196,0.2)',
  tealDark: '#00A9B2',
  transparent: 'rgba(0,0,0,0)',
  white: '#FFFFFF',
};

const defaultTheme = createTheme({
  palette: {
    primary: { main: PALETTE.tealDark },
    secondary: { main: PALETTE.red },
    error: { main: PALETTE.red },
    success: { main: PALETTE.green },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(', '),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hero1: 'h1',
          hero2: 'h1',
          hero3: 'h1',
          subtitle3: 'h6',
          body3: 'p',
          pill: 'span',
          select: 'span',
          tab: 'span',
          button1: 'span',
          button2: 'span',
        },
      },
    },
  },
});
const { breakpoints } = defaultTheme;

const customTheme = {
  palette: {
    ...PALETTE,
    gold: defaultTheme.palette.augmentColor({
      color: {
        main: PALETTE.gold,
      },
    }),
    text: {
      black: PALETTE.neutral50,
      grayLight: '#A1A6B2',
      white: PALETTE.white,
      blueTealGradient: `linear-gradient(52deg, ${PALETTE.bluePrimary} 0%, #3EBAC7 100%)`,
    },
    social: {
      x: '#000',
      facebook: '#1877F2',
      instagram: '#cd486b',
      linkedin: '#0077b5',
    },
  },
  typography: {
    fontSize: FONT_SIZE,
    htmlFontSize: HTML_FONT_SIZE,
    ...Object.fromEntries(
      Object.entries(Typography).map(
        ([key, value]): [string, TypographyVariant] => [
          key,
          { ...value, [breakpoints.down('md')]: MobileTypography[key] },
        ],
      ),
    ),
  },
  zIndex: {
    drawer: 1100,
    appBar: 1200,
    player: 1300,
  },
  gradients: {
    teal: `linear-gradient(85deg, ${PALETTE.blue} 0%, ${PALETTE.teal} 100%)`,
    orange: `linear-gradient(88deg, ${PALETTE.redDark} 0%, ${PALETTE.orange} 100%)`,
    gray: `linear-gradient(85deg, ${PALETTE.blueBlack} 0%, ${PALETTE.blueBlackLight} 100%)`,
    primary: `linear-gradient(85deg, ${PALETTE.blue} 0%, rgba(61, 189, 196, 1) 100%)`,
    primary2: 'linear-gradient(90deg, #2176C3 0%, #3AB4C1 100%)',
    success:
      'linear-gradient(85deg, rgba(30, 126, 52, 1) 0%, rgba(49, 222, 88, 1) 100%)',
    warning:
      'linear-gradient(85deg, rgba(191, 72, 72, 1) 0%, rgba(243, 162, 81, 1) 100%)',
    aquaToBlue: `linear-gradient(270deg, ${PALETTE.blue} 0%, ${PALETTE.teal} 100%)`,
    blueToAqua: `linear-gradient(268deg, ${PALETTE.tealDark} 0%, ${PALETTE.blue} 100%)`,
    orangeDarkToLight: `linear-gradient(224.42deg, ${PALETTE.orange} 0%, ${PALETTE.redDark} 100%)`,
    greyDarkToLight: `linear-gradient(224.42deg, ${PALETTE.blueBlackLight} 0%, ${PALETTE.blueBlack} 100%)`,
    seriesCoverGeneral:
      'linear-gradient(90deg, rgb(46, 56, 77) 0%, rgb(46, 56, 77) 72%, rgba(46, 56, 77, 0) 90%)',
    forgotPassword: `linear-gradient(45deg, ${PALETTE.blue} 0%, ${PALETTE.teal} 100%);`,
  },
  customShadows: {
    modalFooter: '0px -2px 4px rgba(48, 56, 75, 0.10)',
    button:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  },
  dimensions: {
    header: {
      height: 60,
    },
    regionBanner: {
      height: 38,
    },
    sidebar: {
      width: 240,
    },
    episodeCardCover: {
      width: 192,
      height: 168,
    },
    episodeCardToolbar: {
      height: 42,
    },
    episodeCardText: {
      height: 158,
    },
  },
};

export const theme = createTheme(defaultTheme, customTheme);

export type CustomTheme = typeof customTheme;
