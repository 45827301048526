import {
  AccountCircle as AccountCircleIcon,
  Email as EmailIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  PhoneAndroid,
  PhoneIphone,
} from '@mui/icons-material';
import { Box, Hidden, List, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Modals, RoutesConfig, SidebarItems, urls } from '@/constants';
import {
  AssessmentIcon,
  DirectionsIcon,
  ExploreIcon,
  FlagIcon,
  LumiQPictureMarkIcon,
  ViewModuleIcon,
} from '@/icons';
import { AnalyticsService, BUTTON_NAME } from '@/services';
import { selectSessionUserId, uiActions } from '@/store';
import { login, logout } from '@/utils';

const SidebarIcons = {
  [SidebarItems.LEARNING_PATHS.PATH]: <DirectionsIcon />,
  [SidebarItems.TECHNICAL.PATH]: <AssessmentIcon />,
  [SidebarItems.DISCOVER.PATH]: <ExploreIcon />,
  [SidebarItems.LIBRARY.PATH]: <ViewModuleIcon />,
  [SidebarItems.TRACKER.PATH]: <FlagIcon />,
  [SidebarItems.MY_LUMIQ.PATH]: <LumiQPictureMarkIcon />,
};

export const SidebarList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = useSelector(selectSessionUserId);

  const onListItemClick = item => {
    dispatch(uiActions.toggleSidebarDrawer());
    navigate(item.PATH);
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isAndroid = navigator.userAgent.toLowerCase().includes('android');

  return (
    <List sx={{ pt: 0, pb: 10, color: 'text.white' }}>
      {Object.values(SidebarItems).map(item => {
        const isSelected = pathname === item.PATH;
        const Icon = SidebarIcons[item.PATH];

        return (
          <SidebarItem
            key={item.PATH}
            Icon={Icon}
            name={item.NAME}
            isSelected={isSelected}
            onClick={() => onListItemClick(item)}
          />
        );
      })}
      <Hidden mdUp>
        {userId ? (
          <>
            <SidebarItem
              Icon={<AccountCircleIcon />}
              name={RoutesConfig.PROFILE.NAME}
              isSelected={pathname === RoutesConfig.PROFILE.PATH}
              onClick={() => {
                onListItemClick(RoutesConfig.PROFILE);
              }}
            />
            <SidebarItem Icon={<LogoutIcon />} name="Logout" onClick={logout} />
          </>
        ) : (
          <SidebarItem Icon={<LoginIcon />} name="Login" onClick={login} />
        )}
        <SidebarItem
          Icon={<EmailIcon />}
          name="Support"
          onClick={() => {
            dispatch(uiActions.toggleSidebarDrawer());
            window.Intercom?.('show');
          }}
        />
      </Hidden>
      <SidebarItem
        Icon={isAndroid ? <PhoneAndroid /> : <PhoneIphone />}
        name="Download App"
        onClick={() => {
          AnalyticsService.buttonClick({ name: BUTTON_NAME.DOWNLOAD_APP });

          dispatch(uiActions.toggleSidebarDrawer());
          if (isMobile) {
            window.open(urls.downloadApp);
            return;
          }

          dispatch(uiActions.setActiveModal({ name: Modals.APP_DOWNLOAD }));
        }}
      />
    </List>
  );
};

const SidebarItem = ({ isSelected, Icon, onClick, name }) => (
  <Box
    onClick={onClick}
    sx={[
      {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        py: 2,
        pl: 3,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      },
      isSelected && {
        bgcolor: 'blueDark',
        borderColor: 'aqua',
      },
    ]}>
    <Box
      sx={{
        position: 'absolute',
        width: 6,
        left: 0,
        top: 0,
        bottom: 0,
        bgcolor: 'aqua',
        zIndex: 1,
        display: isSelected ? 'block' : 'none',
      }}
    />
    <Box
      sx={{
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        color: isSelected ? 'aqua' : 'text.white',
      }}>
      {Icon}
    </Box>
    <Box>
      <Typography
        variant="body2"
        sx={[
          !isSelected && { color: 'blueBlackLight' },
          isSelected && { fontWeight: 700 },
        ]}>
        {name}
      </Typography>
    </Box>
  </Box>
);
