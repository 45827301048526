import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { Markdown } from '@/components';
import { selectUserRegion } from '@/store';
import { theme } from '@/theme';

import { Resources } from '../../Resources';
import { CPESummary } from './CPESummary';
import { RelatedEpisodes } from './RelatedEpisodes';
import { Speaker } from './Speaker';

export const EpisodeDescription = ({ episode, referrer }) => {
  const region = useSelector(selectUserRegion);
  const { speakers, resources, CPEDetails, relatedEpisodes } = episode;

  return (
    <>
      <Grid container spacing={2}>
        {speakers.map(speaker => (
          <Speaker key={speaker.name} speaker={speaker} />
        ))}
      </Grid>
      <Markdown sx={theme.typography.body2}>{episode.description}</Markdown>
      {episode.resources?.length > 0 && (
        <Resources resources={resources} episode={episode} />
      )}
      {region === 'USA' && CPEDetails && <CPESummary CPEDetails={CPEDetails} />}
      {relatedEpisodes?.length > 0 && (
        <RelatedEpisodes
          relatedEpisodes={relatedEpisodes}
          referrer={{
            ...referrer,
            episodeId: episode.episodeId,
            episodeName: episode.name,
          }}
        />
      )}
    </>
  );
};
