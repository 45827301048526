import { submitAssessment } from '@/requests';
import { AnalyticsService, EVENTS } from '@/services';

import { nodeApi } from '../node-api';
import { NodeApiTags } from '../store.constants';
import { handleOnQueryStartedError } from '../store.utils';

type SubmitAssessmentPayload = {
  assessmentId: string;
  answers: string[];
};

type SubmitAssessmentResponse = {
  pass: boolean;
  score: number;
};

export const userAssessmentsApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    submitAssessment: builder.mutation<
      SubmitAssessmentResponse,
      SubmitAssessmentPayload
    >({
      query: submitAssessment,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.INTENT);
          await queryFulfilled;
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.SUCCESS);
        } catch (error: any) {
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.FAIL, {
            error: error?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (response: any) => ({
        ...response.data,
        score: Math.round(response.data.score * 100) / 100,
      }),
      invalidatesTags: result =>
        result?.pass ? [NodeApiTags.USER_EPISODES] : [],
    }),
  }),
});

export const { useSubmitAssessmentMutation } = userAssessmentsApi;
