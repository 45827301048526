import { Box, Typography } from '@mui/material';

import { AssessmentAnswer, QuizAnswer } from '@/types';

import { Skeleton } from '../Skeleton';

interface AnswerBoxProps {
  answer: QuizAnswer | AssessmentAnswer;
  onSelect: () => void;
  isSelected: boolean;
  index: number;
  isPending?: boolean;
  isDisabled?: boolean;
}

export const AnswerBox = ({
  onSelect,
  answer,
  isSelected,
  index,
  isPending,
  isDisabled,
}: AnswerBoxProps) => {
  const isTestMode = localStorage.getItem('testMode') === 'true';

  return (
    <Box
      onClick={() => {
        if (!isDisabled) onSelect();
      }}
      sx={[
        styles.answerContainer,
        isTestMode && answer.isCorrect && styles.answerCorrect,
        isSelected && styles.answerSelected,
        !isSelected && !isDisabled && styles.answerClickable,
      ]}>
      {isPending ? (
        <Skeleton animation={false} />
      ) : (
        <Typography variant="body1">
          {(index + 10).toString(36).toUpperCase()})&nbsp;{answer.answer}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  answerContainer: {
    border: '1px solid',
    borderColor: 'blueBlack',
    borderRadius: 1,
    p: 2,
    minHeight: 70,
    backgroundColor: 'white',
    color: 'gray',
    alignContent: 'center',
  },
  answerClickable: {
    cursor: 'pointer',
  },
  answerSelected: {
    backgroundColor: 'blue',
    color: 'white',
  },
  answerCorrect: {
    backgroundColor: 'greenLight',
  },
};
