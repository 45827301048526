import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { PrivateRoutes, urls } from '@/constants';
import { selectSessionUserId } from '@/store';
import { getRedirectUrl } from '@/utils';

export const useRouteProtection = () => {
  const { pathname } = useLocation();
  const userId = useSelector(selectSessionUserId);

  const isPrivateRoute = useMemo(() => {
    return Object.values(PrivateRoutes).some(route =>
      matchPath(route, pathname),
    );
  }, [pathname]);

  const isAllowed = userId || !isPrivateRoute;

  useEffect(() => {
    if (!isAllowed) {
      window.location.replace(getRedirectUrl(urls.accounts.login), '_self');
    }
  }, [isAllowed]);

  return { isAllowed };
};
