import { ChevronRight, CloudDownload } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { Link } from '@/containers';
import { AnalyticsService } from '@/services';
import { selectSessionUserId } from '@/store';

export const Resources = ({ resources, variant, episode, chapter }) => {
  const userId = useSelector(selectSessionUserId);
  if (!userId) {
    return;
  }

  return (
    <Box mt={2}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Typography
          variant="h5"
          sx={{
            color: variant === 'player' ? 'white' : 'gray',
          }}>
          Download Files
        </Typography>
        <CloudDownload
          sx={{ ml: 1, color: variant === 'player' ? 'white' : 'gray' }}
        />
      </Box>
      {resources.map((rs, idx) => (
        <Box key={idx} mt={1} mb={1}>
          <Link
            to={rs.file.url}
            target="_blank"
            onClick={() => {
              AnalyticsService.resourceView({
                resourceName: rs.name,
                episode,
                chapter,
              });
            }}>
            <Box sx={{ display: 'flex' }}>
              {idx + 1}. {rs.name}
              <ChevronRight />
            </Box>
          </Link>
        </Box>
      ))}
    </Box>
  );
};
