import { format } from 'date-fns';

import { SaveChapterProgressPayload } from '@/store';
import {
  EpisodeEvaluation,
  EpisodeFeedback,
  ProductFeedback,
  Region,
} from '@/types';

import { NodeEndpoints } from './node.constants';

export const fetchUserRegionRequest = () => ({
  url: NodeEndpoints.USER_REGION,
});

export const updateUserRegionRequest = ({ region }: { region: Region }) => ({
  url: NodeEndpoints.UPDATE_USER_REGION,
  method: 'POST',
  body: { region },
});

export const fetchLastActiveChapter = () => ({
  url: NodeEndpoints.LAST_ACTIVE_CHAPTER,
});

export const fetchUserEpisodesRequest = () => ({
  url: NodeEndpoints.USER_EPISODES,
});

export const fetchEpisodeRecentChapterId = ({
  episodeId,
}: {
  episodeId: string;
}) => ({
  url: NodeEndpoints.EPISODE_RECENT_CHAPTER_ID,
  params: {
    episodeId,
  },
});

export const fetchChapterListenAllowed = ({
  chapterId,
}: {
  chapterId: string;
}) => ({
  url: NodeEndpoints.CHAPTER_LISTEN_ALLOWED,
  params: {
    chapterId,
  },
});

export const fetchChapterProgress = ({ episodeId }: { episodeId: string }) => ({
  url: NodeEndpoints.CHAPTER_PROGRESS,
  params: {
    episodeId,
  },
});

export const saveChapterProgress = ({ event }: SaveChapterProgressPayload) => ({
  url: NodeEndpoints.SAVE_CHAPTER_PROGRESS,
  method: 'POST',
  body: event,
});

export const fetchPlaybackSpeedOptions = () => ({
  url: NodeEndpoints.PLAYBACK_SPEED_OPTIONS,
});

export const fetchTriennialSummaryRequest = () => ({
  url: NodeEndpoints.TRIENNIAL_SUMMARY,
});

export const fetchCertificateDownloadUrlRequest = ({
  certificateId,
}: {
  certificateId: number;
}) => ({
  url: NodeEndpoints.GET_CPD_CERTIFICATE,
  params: {
    id: certificateId,
  },
});

export const fetchCertificateDownloadUrlsRequest = ({
  fromDate,
  toDate,
}: {
  fromDate: string;
  toDate: string;
}) => ({
  url: NodeEndpoints.GET_CPD_CERTIFICATES,
  params: {
    from_date: fromDate,
    to_date: toDate,
  },
});

export const fetchCertificateSummaryRequest = ({
  fromDate,
  toDate,
}: {
  fromDate: string;
  toDate: string;
}) => ({
  url: NodeEndpoints.GET_CERTIFICATE_SUMMARY,
  params: {
    from_date: fromDate,
    to_date: toDate,
  },
});

export const createCustomCPDRecordRequest = ({
  source,
  description,
  credit,
  isEthics,
  dateCompleted,
  fileName,
  fileSize,
  fileUri,
}: {
  source: string;
  description: string;
  credit: number;
  isEthics: boolean;
  dateCompleted: Date;
  fileName?: string;
  fileSize?: number;
  fileUri?: string;
}) => ({
  url: NodeEndpoints.CREATE_CUSTOM_CPD_RECORD,
  method: 'POST',
  body: {
    source,
    description,
    hours: credit,
    ethic_hours: isEthics ? credit : 0,
    date_completed: format(dateCompleted, 'yyyy-MM-dd'),
    file_name: fileName,
    file_size: fileSize,
    file_uri: fileUri,
  },
});

export const updateCustomCPDRecordRequest = ({
  id,
  source,
  description,
  credit,
  isEthics,
  dateCompleted,
  fileName,
  fileSize,
  fileUri,
}: {
  id: number;
  source: string;
  description: string;
  credit: number;
  isEthics: boolean;
  dateCompleted: Date;
  fileName?: string;
  fileSize?: number;
  fileUri?: string;
}) => ({
  url: NodeEndpoints.UPDATE_CUSTOM_CPD_RECORD,
  method: 'POST',
  body: {
    id,
    source,
    description,
    hours: credit,
    ethic_hours: isEthics ? credit : 0,
    date_completed: format(dateCompleted, 'yyyy-MM-dd'),
    file_name: fileName,
    file_size: fileSize,
    file_uri: fileUri,
  },
});

export const deleteCustomCPDRecordRequest = ({ id }: { id: number }) => ({
  url: NodeEndpoints.DELETE_CUSTOM_CPD_RECORD,
  method: 'POST',
  body: {
    id,
  },
});

export const uploadCPDRecordFileRequest = ({
  formData,
}: {
  formData: FormData;
}) => ({
  url: NodeEndpoints.UPLOAD_CPD_RECORD_FILE,
  method: 'POST',
  body: formData,
});

export const fetchUserQuizzes = () => ({
  url: NodeEndpoints.USER_QUIZZES,
});

export const submitQuiz = ({
  quizId,
  answerId,
  region,
}: {
  quizId: string;
  answerId: string;
  region: Region;
}) => ({
  url: NodeEndpoints.SUBMIT_QUIZ,
  method: 'POST',
  body: { quizId, answerId, nasba: region === 'USA' },
});

export const submitAssessment = ({
  assessmentId,
  answers,
}: {
  assessmentId: string;
  answers: string[];
}) => ({
  url: NodeEndpoints.SUBMIT_ASSESSMENT,
  method: 'POST',
  body: { assessmentId, answers },
});

export const redeemEpisode = ({ episodeId }: { episodeId: string }) => ({
  url: NodeEndpoints.REDEEM_EPISODE,
  method: 'POST',
  body: { episodeId },
});

export const bookmarkEpisode = ({
  episodeId,
  bookmarked,
}: {
  episodeId: string;
  bookmarked: boolean;
}) => ({
  url: NodeEndpoints.BOOKMARK_EPISODE,
  method: 'POST',
  body: { episodeId, bookmarked },
});

export const submitEpisodeFeedback = ({
  episodeId,
  feedback,
  episodePlayedDuration,
}: {
  episodeId: string;
  feedback: EpisodeFeedback;
  episodePlayedDuration: number;
}) => ({
  url: NodeEndpoints.SUBMIT_EPISODE_FEEDBACK,
  method: 'POST',
  body: { episodeId, feedback, episodeProgress: episodePlayedDuration },
});

export const submitEpisodeEvaluation = ({
  episodeId,
  evaluation,
}: {
  episodeId: string;
  evaluation: EpisodeEvaluation;
}) => ({
  url: NodeEndpoints.SUBMIT_EPISODE_EVALUATION,
  method: 'POST',
  body: { episodeId, evaluation },
});

export const fetchIsProductFeedbackReady = () => ({
  url: NodeEndpoints.PRODUCT_FEEDBACK_READY,
  method: 'GET',
});

export const submitProductFeedback = ({
  feedback,
}: {
  feedback: ProductFeedback;
}) => ({
  url: NodeEndpoints.SUBMIT_PRODUCT_FEEDBACK,
  method: 'POST',
  body: { feedback },
});
