import {
  AccountCircle,
  Email,
  Logout,
  ManageAccounts,
} from '@mui/icons-material';
import { Box, Hidden, IconButton, Menu, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Routes } from '@/constants';
import { uiActions } from '@/store';

export const ProfileMenu = ({ logout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = event => {
    event.stopPropagation();
    dispatch(uiActions.closeSidebarDrawer());
    setIsOpen(true);
  };

  const handleClose = event => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <Hidden mdDown>
      <IconButton onClick={handleClick}>
        <AccountCircle sx={styles.appIcon} ref={menuRef} />
      </IconButton>
      <Menu
        anchorEl={menuRef.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              mt: 1,
              border: 1,
              borderColor: 'grayMid',
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 10,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}>
        <Box sx={styles.menuContainer}>
          <ProfileMenuItem
            label="Profile"
            Icon={ManageAccounts}
            onClick={() => {
              navigate(Routes.PROFILE);
              setIsOpen(false);
            }}
          />
          <ProfileMenuItem
            label="Support"
            Icon={Email}
            onClick={() => {
              window.Intercom?.('show');
              setIsOpen(false);
            }}
          />
          <ProfileMenuItem label="Logout" Icon={Logout} onClick={logout} />
        </Box>
      </Menu>
    </Hidden>
  );
};

const ProfileMenuItem = ({ onClick, label, Icon }) => (
  <Box
    sx={{
      py: 1,
      px: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'tealDark',
      '&:hover': {
        bgcolor: 'action.hover',
      },
    }}
    onClick={onClick}>
    <Icon sx={{ mr: 2, width: 18 }} />
    <Typography variant="subtitle2">{label}</Typography>
  </Box>
);

const styles = {
  appIcon: {
    color: 'white',
    width: 32,
    height: 32,
    px: 0,
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
};
