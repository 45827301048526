import { ChevronRight } from '@mui/icons-material';
import { Box, Button as MuiButton, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthInfo, Button } from '@/components';
import { Routes, urls } from '@/constants';
import { Link } from '@/containers';
import { appleLogo, googleLogo } from '@/images';
import { useLoginMutation } from '@/store';
import { getOauthURL } from '@/utils';

import { FormConfig } from './utils';

export const Login = () => {
  const location = useLocation();
  const inputRef = useRef();

  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });
  const [failedSubmitCount, setFailedSubmitCount] = useState(0);

  const [login, { isLoading }] = useLoginMutation();

  const onChange = ({ target: { id, value } }) => {
    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  const onSubmit = async event => {
    event.preventDefault();

    const newErrors = {};
    Object.keys(FormConfig).forEach(id => {
      if (!FormConfig[id].validator(form)) {
        newErrors[id] = FormConfig[id].errorMessage;
      }
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    const { error: requestError } = await login({
      email: form.email.trim(),
      password: form.password.trim(),
    });

    if (requestError) {
      setFailedSubmitCount(prevState => prevState + 1);
      setErrors({
        email:
          failedSubmitCount >= 2
            ? 'Try entering a different email, like your company or personal email.'
            : null,
        password:
          (requestError?.status === 400 && requestError?.data?.message) ||
          'Something went wrong. Please try again later.',
      });
      return;
    }

    setFailedSubmitCount(0);

    /*
      No redirect needed here because this is an anonymous-only route
      and AuthSessionLoader will trigger the redirect on auth state change
    */
  };

  const googleOauthURL = getOauthURL('google');
  const appleOauthURL = getOauthURL('apple');

  return (
    <form onSubmit={onSubmit}>
      <Box sx={styles.pageContainer}>
        <AuthInfo inputRef={inputRef} />
        <Box sx={styles.pageSection} ref={inputRef}>
          <Typography variant="h1">Login</Typography>
          <Box sx={styles.textFieldContainer}>
            <Typography variant="body3">Email</Typography>
            <TextField
              id="email"
              onChange={onChange}
              error={!!errors.email}
              fullWidth
              autoComplete="email"
            />
            {errors.email && (
              <Typography color="red" variant="body3">
                {errors.email}
              </Typography>
            )}
          </Box>
          <Box sx={styles.textFieldContainer}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body3">Password</Typography>
              <Box sx={{ ml: 'auto' }}>
                <Link to={Routes.FORGOT_PASSWORD} inline>
                  <Typography color="gray" variant="body3">
                    Forgot Password?
                  </Typography>
                </Link>
              </Box>
            </Box>
            <TextField
              type="password"
              id="password"
              onChange={onChange}
              error={!!errors.password}
              fullWidth
              autoComplete="current-password"
            />
            {errors.password && (
              <Typography color="red" variant="body3">
                {errors.password}
              </Typography>
            )}
          </Box>
          <Box sx={styles.submitContainer}>
            <Button
              disabled={isLoading}
              label="Login"
              skinny={false}
              type="submit"
              sx={{ flex: 1 }}
            />
            <Box sx={styles.registerLinkContainer}>
              <Typography sx={{ textWrap: 'nowrap' }}>New to LumiQ?</Typography>
              <Link
                to={{ pathname: Routes.REGISTER, search: location.search }}
                inline>
                <Typography variant="body3" sx={{ textWrap: 'nowrap' }}>
                  Create an Account! &gt;
                </Typography>
              </Link>
            </Box>
          </Box>
          <Typography variant="h1" sx={{ mt: 4 }}>
            Or Log in With
          </Typography>
          <MuiButton href={googleOauthURL} sx={styles.oauthButton}>
            <Box
              component="img"
              alt="google logo"
              src={googleLogo}
              sx={styles.oauthIcon}
            />
            <Typography variant="button1">Google</Typography>
            <ChevronRight sx={{ ml: 'auto' }} />
          </MuiButton>
          <MuiButton href={appleOauthURL} sx={styles.oauthButton}>
            <Box
              component="img"
              alt="apple logo"
              src={appleLogo}
              sx={styles.oauthIcon}
            />
            <Typography variant="button1">Apple</Typography>
            <ChevronRight sx={{ ml: 'auto' }} />
          </MuiButton>
          <Box sx={{ mt: 'auto', pt: 4 }}>
            <Typography component="span">
              By clicking one of the options above, you agree to LumiQ's{' '}
            </Typography>
            <Link inline to={urls.lumiqTC}>
              Terms & Conditions
            </Link>{' '}
            <Typography component="span">and acknowledge our </Typography>
            <Link inline to={urls.lumiqPrivacy}>
              Privacy Policy
            </Link>
            <Typography component="span">.</Typography>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

const styles = {
  pageContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  pageSection: {
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: 4,
      sm: 8,
    },
    flex: 1,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: 2,
  },
  submitContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    mt: 3,
  },
  registerLinkContainer: {
    ml: {
      xs: 0,
      sm: 2,
    },
    mt: {
      xs: 2,
      sm: 0,
    },
    flex: 1,
  },
  oauthButton: {
    mt: 2,
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'blueBlack',
    border: theme => `1px solid ${theme.palette.blueBlack}`,
    p: 2,
  },
  oauthIcon: {
    height: 30,
    mr: 2,
  },
};
