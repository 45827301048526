import _ from 'lodash';

const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const MILLISECONDS_PER_SECONDS = 1000;

// TODO review all of these + write tests

/**
 * converts the given seconds to a duration timestamp in the format hh:mm:ss
 * NOTE only works for times less than 1 day!
 */
export const convertSecondsToHoursMinutes = (seconds, longForm = false) => {
  const durationInMinutes = Math.round(seconds / SECONDS_PER_MINUTE);

  const hour = Math.floor(durationInMinutes / MINUTES_PER_HOUR);
  const minute = durationInMinutes % MINUTES_PER_HOUR;

  let hDisplay;
  let mDisplay;
  if (longForm) {
    hDisplay = hour > 0 ? `${hour} hr${hour > 1 ? 's' : ''} ` : '';
    mDisplay = minute > 0 ? `${minute} min${minute > 1 ? 's' : ''}` : '';
  } else {
    hDisplay = hour > 0 ? `${hour}h ` : '';
    mDisplay = minute > 0 ? `${minute}m` : '';
  }

  return hDisplay + mDisplay;
};

/**
 * converts the given milliseconds to a duration timestamp in the format hh:mm:ss
 * NOTE only works for times less than 1 day!
 */
export const convertMsToHHMMSS = milliseconds => {
  try {
    if (milliseconds) {
      // less than 1 minute, return the string in 0:ss format (with leading single zero!)
      if (milliseconds < SECONDS_PER_MINUTE * MILLISECONDS_PER_SECONDS) {
        return new Date(milliseconds)
          .toISOString()
          .substr(14, 5)
          .replace(/^0/, '');
      }

      // for all else, return the string with no leading zeros (hh:mm:ss, h:mm:ss, mm:ss or m:ss)
      return new Date(milliseconds)
        .toISOString()
        .substr(11, 8)
        .replace(/^[0:]+/, '');
    }

    return '0:00';
  } catch (error) {
    throw new Error(`Error converting ${milliseconds} HHMMSS.`, {
      cause: error,
    });
  }
};

/**
 * converts the given seconds to a duration timestamp in the format hh:mm:ss
 * NOTE only works for times less than 1 day!
 */
export const convertSecondsToHHMMSS = seconds => {
  return convertMsToHHMMSS(seconds * 1000);
};

export const convertHHMMSStoSeconds = string => {
  if (!string) return 0;

  const parts = string.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parseInt(parts[2], 10);

  return hours * 3600 + minutes * 60 + seconds;
};

export const ageInHours = dateString =>
  dateString
    ? Math.floor(
        (new Date().getTime() - new Date(dateString).getTime()) /
          (1000 * 60 * 60),
      )
    : undefined;

export const camelCaseKeys = obj => {
  if (!obj || !_.isObject(obj)) {
    return obj;
  }
  return _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);

    acc[camelKey] = _.isObject(value) ? camelCaseKeys(value) : value;
  });
};

export const snakeCaseKeys = obj => {
  if (!obj || !_.isObject(obj)) {
    return obj;
  }
  return _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.snakeCase(key);

    acc[camelKey] = _.isObject(value) ? snakeCaseKeys(value) : value;
  });
};

export const convertSecondsToMinutes = seconds => {
  return Math.round(seconds / SECONDS_PER_MINUTE);
};

/**
 * converts the given number into a string with n decimal places
 * or a string with no decimal places if the number is an integer.
 */
export const roundFloat = (num, decimalPlaces) =>
  num.toFixed(decimalPlaces).replace(/\.0+$/, '');

export const formatCost = cost => cost.toFixed(2);

/** display the file size in simple manner */
export function displaySize(size) {
  if (size >= 2 ** 30) {
    return `${Math.floor(size / 2 ** 30)} GB`;
  }
  if (size >= 2 ** 20) {
    return `${Math.floor(size / 2 ** 20)} MB`;
  }
  if (size >= 2 ** 10) {
    return `${Math.floor(size / 2 ** 10)} KB`;
  }
  return `${size} byte${size > 1 ? 's' : ''}`;
}

/**
 * Given a UTC date string (usually from the backend) convert it to a date but
 * with only year, month and day in the client's local time.
 */
export const getLocalDate = UTCStr => {
  const date = new Date(UTCStr);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();

  return new Date(year, month, day);
};
