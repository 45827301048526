import { Box, Hidden } from '@mui/material';
import { useSelector } from 'react-redux';

import { Button } from '@/components';
import { urls } from '@/constants';
import { selectSessionUserId } from '@/store';
import { getRedirectUrl, login, logout } from '@/utils';

import { ProfileMenu } from './components';

export const NavbarActions = () => {
  const userId = useSelector(selectSessionUserId);

  if (userId) {
    return (
      <Box>
        <ProfileMenu logout={logout} />
      </Box>
    );
  }

  return (
    <Hidden mdDown>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          label="About Us"
          variant="tertiary"
          fullWidth={false}
          onClick={() => window.open(urls.whatsLumiq, '_blank')}
        />
        <Button
          label="Login"
          variant="tertiary"
          fullWidth={false}
          onClick={login}
        />
        <Button
          label="Signup"
          variant="tertiary"
          fullWidth={false}
          onClick={() =>
            (window.location.href = getRedirectUrl(urls.accounts.register))
          }
        />
      </Box>
    </Hidden>
  );
};
