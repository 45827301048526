import mixpanel from 'mixpanel-browser';

import config from '@/config';
import { urls } from '@/constants';
import { getIsEpisodeEthics } from '@/store';
import { ageInHours, convertSecondsToHoursMinutes } from '@/utils';

import { growthbook } from '../features';
import { sessionConfig, validateParamValue } from './config';
import { EVENT_CONSTANTS, EVENTS } from './events';

let userProperties = {};
let userTags = {};
let featureFlags = {};
const queuedEvents = []; // Queue of events that fire before config is completed

export function initialize({
  userId,
  playerConfig,
  userProfile,
  userRegion,
  organizationName,
  userTags: tags,
}) {
  mixpanel.init(config.MIX_PANEL_PROJECT_TOKEN, {
    api_host: urls.actions.mixpanel,
    loaded(mp) {
      sessionConfig.checkSessionId();
      const originalTrack = mp.track;
      mp.track = function () {
        sessionConfig.checkSessionId();
        mp.register({ last_event_time: Date.now() });
        originalTrack.apply(mp, arguments);
      };
    },
  });

  userProperties = {
    $distinct_id: userId,
    nasba: userRegion === 'USA',
    $email: userProfile?.email,
    ...(organizationName ? { organizationName } : {}),
    ...(userProfile
      ? {
          $name: `${userProfile.firstName} ${userProfile.lastName}`,
          signupDate: userProfile.userCreatedAt,
          onboardingDate: userProfile.onboardingCompletedAt,
        }
      : {}),
    ...Object.fromEntries(
      Object.entries(playerConfig || {}).map(([key, value]) => [
        `player_${key}`,
        value,
      ]),
    ),
  };
  userTags =
    tags?.reduce((obj, tag) => {
      const [key, value] = tag.split(':');
      const fieldName = `$user_tag - ${key}`;
      return { ...obj, [fieldName]: [...(obj[fieldName] || []), value] };
    }, {}) || {};

  if (userId) {
    mixpanel.identify(userId);
    mixpanel.people.set(userProperties);
    mixpanel.people.set(userTags);
  }

  while (queuedEvents.length) {
    const { eventName, data } = queuedEvents.shift();
    logEvent(eventName, data);
  }
}

export const updateUserProfile = ({ name, email }) => {
  userProperties = {
    ...userProperties,
    $name: name,
    $email: email,
  };
  mixpanel.people.set(userProperties);
};

export const updateFeatureFlags = flags => {
  featureFlags = flags;
};

/* Events */

export const pageLoad = ({ queryParams = {}, campaignId = null } = {}) => {
  logEvent(EVENTS.PAGE_EVENTS.LOAD, { queryParams, campaignId });
};

export const pageView = (state = {}) => {
  logEvent(EVENTS.PAGE_EVENTS.VIEW, {
    referrer: state ? validateParamValue(state.referrer) : {},
  });
};

export const pageViewEpisode = episode => {
  logEvent(EVENTS.PAGE_EVENTS.VIEW_EPISODE, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
  });
};

export const clickThrough = ({ target, referrer, section, resourceId }) => {
  logEvent(EVENTS.CLICK_THROUGH, {
    target,
    location: validateParamValue(referrer),
    referrer: validateParamValue(referrer),
    section,
    resourceId,
  });
};

// UNUSED
export const userOnboard = ({
  onboardingCompleted,
  designation,
  exactDesignation,
}) => {
  logEvent(EVENTS.USER_ONBOARD, {
    onboardingCompleted: onboardingCompleted.toISOString(),
    designation,
    exactDesignation,
  });
};

export function chapterPlay({
  chapter,
  episode,
  isTrailer,
  isVerifiable,
  type,
  location,
  referrer,
}) {
  logEvent(EVENTS.CHAPTER_EVENTS.PLAY, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    chapterDuration: chapter.duration,
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    isTrailer,
    isVerifiable,
    type,
    location: validateParamValue(location),
    referrer: validateParamValue(referrer),
  });
}

export function chapterPause(
  { chapter, episode, isTrailer, isVerifiable, type, location, referrer },
  paused,
) {
  logEvent(
    paused ? EVENTS.CHAPTER_EVENTS.PAUSE : EVENTS.CHAPTER_EVENTS.RESUME,
    {
      chapterId: chapter.chapterId,
      chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
      chapterDuration: chapter.duration,
      episodeId: episode.episodeId,
      episodeName: validateParamValue(episode.name),
      episodeDuration: episode.duration,
      formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
      isTrailer,
      isVerifiable,
      type,
      location: validateParamValue(location),
      referrer: validateParamValue(referrer),
    },
  );
}

export function chapterSeek({
  alreadyListened,
  chapter,
  episode,
  isTrailer,
  isVerifiable,
  isNasba,
  seekStart,
  seekLength,
  type,
}) {
  logEvent(EVENTS.CHAPTER_EVENTS.SEEK, {
    alreadyListened,
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    chapterDuration: chapter.duration,
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    isTrailer,
    isVerifiable,
    isNasba,
    seekStart,
    seekLength,
    type,
  });
}

export function chapterStart({
  chapter,
  episode,
  isNasba,
  location,
  referrer,
}) {
  logEvent(EVENTS.CHAPTER_EVENTS.START, {
    chapterId: chapter.chapterId,
    chapterName: validateParamValue(chapter.name),
    chapterDuration: chapter.duration,
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    location: validateParamValue(location),
    referrer: validateParamValue(referrer),
    isNasba,
  });
}

export function chapterComplete({ chapter, episode, isNasba }) {
  logEvent(EVENTS.CHAPTER_EVENTS.COMPLETE, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    chapterDuration: chapter.duration,
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    isNasba,
  });
}

export function playbackSpeedChanged({ speed, chapter, episode, location }) {
  logEvent(EVENTS.CHAPTER_EVENTS.PLAYBACK_SPEED_CHANGED, {
    speed,
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    chapterDuration: chapter.duration,
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    location: validateParamValue(location),
  });

  logEvent(EVENTS.TRACK_PLAYER_EVENTS.CHANGE_SPEED, {
    speed,
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    location: validateParamValue(location),
  });
}

export function trackPlayerPlayPause(
  { chapter, episode, isTrailer, isVerifiable, type, location, referrer },
  paused,
) {
  logEvent(
    paused
      ? EVENTS.TRACK_PLAYER_EVENTS.PAUSE
      : EVENTS.TRACK_PLAYER_EVENTS.PLAY_RESUME,
    {
      chapterId: chapter.chapterId,
      chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
      episodeId: episode.episodeId,
      episodeName: validateParamValue(episode.name),
      isTrailer,
      isVerifiable,
      type,
      location: validateParamValue(location),
      referrer: validateParamValue(referrer),
    },
  );
}

export function trackPlayerSeek({
  alreadyListened,
  chapter,
  episode,
  isTrailer,
  isVerifiable,
  isNasba,
  seekStart,
  seekLength,
  type,
  location,
}) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.CHANGE_POSITION, {
    alreadyListened,
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    isTrailer,
    isVerifiable,
    isNasba,
    seekStart,
    seekLength,
    type,
    location: validateParamValue(location),
  });
}

export function trackPlayerVolume({
  chapter,
  episode,
  val,
  isNasba,
  location,
}) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.ADJUST_VOLUME, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    volume: val,
    isNasba,
    location: validateParamValue(location),
  });

  if (val <= 0) {
    logEvent(EVENTS.TRACK_PLAYER_EVENTS.MUTE, {
      chapterId: chapter.chapterId,
      chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
      episodeId: episode.episodeId,
      episodeName: validateParamValue(episode.name),
      volume: val,
      isNasba,
      location: validateParamValue(location),
    });
  }
}

export function trackPlayerSkip({ chapter, episode, direction }) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.SKIP_CHAPTER, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    skipDirection: direction,
  });
}

export function trackPlayerRewind({ chapter, episode, location }) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.REWIND, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    isTrailer: !!chapter.isTrailer,
    isVerifiable: !!chapter.isVerifiable,
    location: validateParamValue(location),
  });
}

export function trackPlayerViewEpisode({
  chapter,
  episode,
  location,
  referrer,
}) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.VIEW_EPISODE_DETAILS, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    location,
    referrer,
  });
}

export function trackPlayerFeedback({ chapter, episode }) {
  logEvent(EVENTS.TRACK_PLAYER_EVENTS.EPISODE_FEEDBACK, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name,
    chapterDuration: chapter.duration,
    chapterPosition: chapter.chapterPosition,
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
  });
}

export function videoPlayerFullScreen({ chapter, episode, isFullScreen }) {
  logEvent(EVENTS.VIDEO_PLAYER_EVENTS.FULLSCREEN, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name ? validateParamValue(chapter.name) : 'trailer',
    episodeId: episode.episodeId,
    episodeName: validateParamValue(episode.name),
    isFullScreen,
  });
}

// UNUSED - Dev only?
export function debug(eventName, data) {
  logEvent(EVENTS.DEBUG, { eventName, ...data });
}

export function episodeStart({
  chapter,
  episode,
  isNasba,
  location,
  referrer,
}) {
  logEvent(EVENTS.EPISODE_EVENTS.START, {
    chapterId: chapter.chapterId,
    chapterName: chapter.name,
    chapterDuration: chapter.duration,
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    location: validateParamValue(location),
    referrer: validateParamValue(referrer),
    isNasba,
  });
}

export function episodeListened({ episode, isNasba }) {
  logEvent(EVENTS.EPISODE_EVENTS.LISTENED, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    isNasba,
  });
}

export function episodeComplete({ episode, isNasba }) {
  logEvent(EVENTS.EPISODE_EVENTS.COMPLETE, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    isNasba,
  });
}

export function episodeImpression({ episode, location, referrer }) {
  logEvent(EVENTS.EPISODE_EVENTS.IMPRESSION, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    location: validateParamValue(location),
    referrer: validateParamValue(referrer),
  });
}

export function learningPathImpression({ learningPath, location }) {
  logEvent(EVENTS.LEARNING_PATH_EVENTS.IMPRESSION, {
    learningPathId: learningPath.groupId,
    learningPathTitle: learningPath.title,
    location: validateParamValue(location),
  });
}

export function episodeRedeem({ episode }) {
  logEvent(EVENTS.EPISODE_EVENTS.REDEEM, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
  });
}

export function episodeShare({ episode, location }) {
  logEvent(EVENTS.EPISODE_EVENTS.SHARE, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    location: validateParamValue(location),
  });
}

export function bookmark({ episode, value, location, referrer }) {
  logEvent(EVENTS.EPISODE_EVENTS.BOOKMARK, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    value,
    location: validateParamValue(location),
    referrer: validateParamValue(referrer),
  });

  if (location?.component === EVENT_CONSTANTS.COMPONENT.MEDIA_CONTROLS) {
    logEvent(EVENTS.TRACK_PLAYER_EVENTS.BOOKMARK, {
      episodeId: episode.episodeId,
      episodeName: episode.name,
      episodeFormat: episode.isVideo ? 'video' : 'audio',
      episodeDuration: convertSecondsToHoursMinutes(episode.duration),
      formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
      episodeChapters: episode.chapters?.length || 'N/A',
      episodeTypes: [
        ...(episode.isRecordedLive ? ['live'] : []),
        ...(episode.isPremium ? ['premium'] : []),
        ...(getIsEpisodeEthics({
          episode,
          userRegion: userProperties.nasba ? 'USA' : 'CAN',
        })
          ? ['ethics']
          : []),
      ],
      value,
      location: validateParamValue(location),
      referrer: validateParamValue(referrer),
    });
  }
}

export function episodeFeedback({
  episodeRating,
  episode,
  chapter,
  chapterProgress,
  episodePlayedDuration,
  textFeedback,
  ratingReasons,
  version,
}) {
  logEvent(EVENTS.EPISODE_FEEDBACK_EVENTS.FEEDBACK, {
    episodeRating,
    chapterId: chapter.chapterId,
    chapterName: chapter.name,
    chapterDuration: chapter.duration,
    chapterPosition: chapter.chapterPosition,
    chapterProgress: chapterProgress.progress?.playedDuration,
    episodePlayedDuration,
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    ratingReasons,
    textFeedback,
    version,
  });
}

export function closeFeedbackModal(params) {
  const episode = params.episode;
  logEvent(EVENTS.EPISODE_FEEDBACK_EVENTS.CLOSE, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
  });
}

export function productFeedback({
  productRating,
  isDismissed,
  textFeedback,
  referrer,
}) {
  logEvent(EVENTS.PRODUCT_FEEDBACK, {
    productRating,
    isDismissed,
    referrer: validateParamValue(referrer),
    textFeedback,
  });
}

export function episodeEvaluation({
  enjoyedPodcast,
  episode,
  textFeedback,
  q1,
  q2,
  q3,
  q4,
  q5,
}) {
  logEvent(EVENTS.EPISODE_FEEDBACK_EVENTS.EVALUATION, {
    enjoyedPodcast,
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    textFeedback,
    q1,
    q2,
    q3,
    q4,
    q5,
  });
}

export function technicalSearchViewEpisode({ episode, location }) {
  logEvent(EVENTS.TECHNICAL_SEARCH_EVENTS.VIEW_EPISODE, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    location: validateParamValue(location),
  });
}

export function episodeDetailSwitchTab({ episode, tab }) {
  logEvent(EVENTS.EPISODE_DETAIL_EVENTS.SWITCH_TAB, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
    tab,
  });
}

// UNUSED
export function notificationPermissionDenied() {
  logEvent(EVENTS.NOTIFICATION_PERMISSION_DENIED);
}

// UNUSED
export function userShared({ episodeId, screen }) {
  logEvent(EVENTS.USER_SHARED, {
    episodeId: episodeId || '',
    screen: screen || '',
  });
}

// UNUSED
export function userFreeTrialShare() {
  logEvent(EVENTS.FREE_TRIAL_SHARE);
}

export function visualAidView({ episode }) {
  logEvent(EVENTS.VISUAL_AID_VIEW, {
    episodeId: episode.episodeId,
    episodeName: episode.name,
    episodeFormat: episode.isVideo ? 'video' : 'audio',
    episodeDuration: episode.duration,
    formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
    episodeChapters: episode.chapters?.length || 'N/A',
    episodeTypes: [
      ...(episode.isRecordedLive ? ['live'] : []),
      ...(episode.isPremium ? ['premium'] : []),
      ...(getIsEpisodeEthics({
        episode,
        userRegion: userProperties.nasba ? 'USA' : 'CAN',
      })
        ? ['ethics']
        : []),
    ],
  });
}

export function resourceView({ resourceName, episode, chapter }) {
  if (episode) {
    logEvent(EVENTS.RESOURCE_VIEW, {
      resourceName,
      episodeId: episode.episodeId,
      episodeName: episode.name,
      episodeFormat: episode.isVideo ? 'video' : 'audio',
      episodeDuration: episode.duration,
      formattedEpisodeDuration: convertSecondsToHoursMinutes(episode.duration),
      episodeChapters: episode.chapters?.length || 'N/A',
      episodeTypes: [
        ...(episode.isRecordedLive ? ['live'] : []),
        ...(episode.isPremium ? ['premium'] : []),
        ...(getIsEpisodeEthics({
          episode,
          userRegion: userProperties.nasba ? 'USA' : 'CAN',
        })
          ? ['ethics']
          : []),
      ],
    });
  } else if (chapter) {
    logEvent(EVENTS.RESOURCE_VIEW, {
      resourceName,
      chapterId: chapter.chapterId,
      chapterName: chapter.name,
      chapterDuration: chapter.duration,
      chapterPosition: chapter.chapterPosition,
    });
  } else {
    logEvent(EVENTS.RESOURCE_VIEW, { resourceName });
  }
}

export function quizAttempt({ chapterId, chapterName, quizId, answer, pass }) {
  logEvent(EVENTS.QUIZ_EVENTS.ATTEMPT, {
    chapterId,
    chapterName,
    quizId,
    answer,
    pass,
  });
  logEvent(pass ? EVENTS.QUIZ_EVENTS.PASS : EVENTS.QUIZ_EVENTS.FAIL, {
    chapterId,
    chapterName,
    quizId,
    answer,
    pass,
  });
}

export function assessmentAttempt({
  episodeId,
  assessmentId,
  episodeName,
  answer,
  score,
  pass,
}) {
  const assessmentDetails = {
    episodeId,
    assessmentId,
    episodeName: validateParamValue(episodeName),
    answer,
    score,
    pass,
  };

  logEvent(EVENTS.ASSESSMENT_EVENTS.ATTEMPT, assessmentDetails);
  logEvent(
    pass ? EVENTS.ASSESSMENT_EVENTS.PASS : EVENTS.ASSESSMENT_EVENTS.FAIL,
    assessmentDetails,
  );
}

export function filterLibrary({
  filters,
  categoriesFilter,
  fieldsOfStudyFilter,
}) {
  logEvent(EVENTS.FILTER_LIBRARY_EVENTS.FILTER, {
    categoriesFilter,
    fieldsOfStudyFilter,
    sortBy: filters.sortBy,
    mediaType: filters.format,
    hidePlayed: filters.hidePlayed,
    showEthics: filters.onlyEthics,
  });
}

export function filterLibraryClear({ categoryName }) {
  logEvent(
    categoryName === EVENT_CONSTANTS.CLEAR_ALL
      ? EVENTS.FILTER_LIBRARY_EVENTS.FILTER_CLEAR
      : EVENTS.FILTER_LIBRARY_EVENTS.FILTER_REMOVE,
    { categoryName },
  );
}

export function technicalSearchFilter({
  selectedRegions,
  selectedCategory,
  selectedTopic,
}) {
  logEvent(EVENTS.TECHNICAL_SEARCH_EVENTS.FILTER, {
    selectedRegions,
    selectedCategory,
    selectedTopic,
  });
}

/* Local Functions */

export function showModal(name) {
  logEvent(EVENTS.MODAL_EVENTS.OPEN, { modalName: name });
}

export function closeModal(name) {
  logEvent(EVENTS.MODAL_EVENTS.CLOSE, { modalName: name });
}

export function buttonClick(name) {
  logEvent(EVENTS.BUTTON_CLICK, { buttonName: name });
}

export function logEvent(eventName, data = {}) {
  if (!mixpanel.config) {
    queuedEvents.push({ eventName, data });
    return;
  }

  const blacklist = growthbook
    .getFeatureValue('event-blacklist', '')
    .split('|');

  if (blacklist.includes(eventName)) {
    console.info('Skipping blacklisted event:', eventName);
    return;
  }

  const { referrer, ...otherData } = data;
  const payload = {
    ...(otherData || {}),
    ...userProperties,
    ...userTags,
    ...featureFlags,
  };

  if (payload.onboardingDate) {
    payload.onboardingAge = ageInHours(userProperties.onboardingDate);
  }

  if (payload.signupDate) {
    payload.signupAge = ageInHours(userProperties.signupDate);
  }

  if (
    process.env.NODE_ENV === 'development' &&
    localStorage.getItem('analyticsConsoleLogs')
  ) {
    console.info(eventName, payload);
  }
  mixpanel.track(eventName, payload);
}
