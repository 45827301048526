import { useFeatureValue } from '@growthbook/growthbook-react';
import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Skeleton } from '@/components';
import { Routes } from '@/constants';
import { PlayArrowFilledIcon, PlayArrowOutlinedIcon } from '@/icons';
import { EVENT_CONSTANTS } from '@/services';
import { useFetchEpisodesQuery } from '@/store';
import { convertSecondsToHoursMinutes, createRouteWithParams } from '@/utils';

import { OnboardingFormStepsContext } from '../OnboardingForm';
import { styles } from '../styles';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalTitle } from './ModalTitle';

const HEADER_HEIGHT = 24;
const IMAGE_HEIGHT = { xs: 132, md: 174 };
const CARD_HEIGHT = HEADER_HEIGHT + IMAGE_HEIGHT.md;
const REFERRER = {
  page: EVENT_CONSTANTS.PAGE.ONBOARDING,
  component: EVENT_CONSTANTS.COMPONENT.EPISODE_CARD,
};

export const RecommendedEpisodes = ({ goToNextStep }) => {
  const navigate = useNavigate();
  const onboardingEpisodes = useFeatureValue('onboarding-episodes', '');

  const { userRegion } = useContext(OnboardingFormStepsContext);

  const { data: episodes, isLoading } = useFetchEpisodesQuery({
    episodeIds: onboardingEpisodes.split('|'),
    region: userRegion,
  });

  const play = ({ episodeId }) => {
    const queryParams = new URLSearchParams({
      playerAction: 'episodePlayPressed',
      playerActionPayload: JSON.stringify({
        episodeId,
        location: { ...REFERRER, button: EVENT_CONSTANTS.BUTTON.PLAY },
        referrer: REFERRER,
      }),
    });

    navigate(
      {
        pathname: createRouteWithParams(Routes.EPISODE, {
          episodeId,
        }),
        search: queryParams.toString(),
      },
      { replace: true },
    );

    goToNextStep();
  };

  const preview = ({ episodeId, trailerId }) => {
    const queryParams = new URLSearchParams({
      playerAction: 'chapterPlayPressed',
      playerActionPayload: JSON.stringify({
        activeChapter: { episodeId, chapterId: trailerId },
        location: { ...REFERRER, button: EVENT_CONSTANTS.BUTTON.PREVIEW },
        referrer: REFERRER,
      }),
    });

    navigate(
      {
        pathname: createRouteWithParams(Routes.EPISODE, {
          episodeId,
        }),
        search: queryParams.toString(),
      },
      { replace: true },
    );

    goToNextStep();
  };

  return (
    <Box
      sx={[
        styles.formContainer,
        {
          bgcolor: 'neutral20',
        },
      ]}>
      <Header Title={<Title userRegion={userRegion} />} />
      <Box
        sx={[
          styles.formPrimarySection,
          { display: 'flex', flexDirection: 'column', gap: 2 },
        ]}>
        {isLoading && (
          <>
            <Skeleton variant="rounded" height={CARD_HEIGHT} />
            <Skeleton variant="rounded" height={CARD_HEIGHT} />
          </>
        )}
        {!isLoading &&
          episodes?.map(episode => (
            <Box
              key={episode.episodeId}
              sx={{
                bgcolor: 'white',
                color: 'text.black',
                border: 1,
                borderRadius: 4,
                borderColor: 'neutral35',
                width: '100%',
                height: { md: CARD_HEIGHT },
                pt: `${HEADER_HEIGHT}px`,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                overflow: 'clip',
                position: 'relative',
              }}>
              <Box
                sx={{
                  bgcolor: 'blue50',
                  width: '100%',
                  height: HEADER_HEIGHT,
                  position: 'absolute',
                  top: 0,
                  textAlign: 'center',
                }}>
                <Typography variant="caption" fontWeight={'bold'} color="white">
                  {userRegion === 'USA'
                    ? `${episode.credits} credit${episode.credits > 1 ? 's' : ''} of verifiable CPE`
                    : `${convertSecondsToHoursMinutes(episode.duration)} of verifiable CPD`}
                </Typography>
              </Box>
              <Box sx={{ position: 'relative' }}>
                <Box
                  component="img"
                  src={episode.cover}
                  sx={{
                    flexShrink: 0,
                    width: { xs: '100%', md: 180 },
                    height: IMAGE_HEIGHT,
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: { xs: 8, md: 0 },
                    left: 8,
                  }}>
                  <Box
                    component="img"
                    src={episode.companyLogo}
                    sx={{
                      width: 48,
                      height: 48,
                      objectFit: 'cover',
                      borderRadius: 2,
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  px: 2,
                  py: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                <Box>
                  <Typography variant="h5" mb={0.5}>
                    {episode.name}
                  </Typography>
                  <Typography variant="body3" sx={{ mb: { xs: 1, md: 0 } }}>
                    {episode.hook}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    label="Play"
                    variant="secondary"
                    fullWidth={false}
                    startIcon={<PlayArrowFilledIcon />}
                    sx={{ mr: 1 }}
                    onClick={() => play(episode)}
                  />
                  <Button
                    label="Preview"
                    variant="tertiary"
                    fullWidth={false}
                    startIcon={<PlayArrowOutlinedIcon />}
                    onClick={() => preview(episode)}
                  />
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Footer handleNext={() => goToNextStep()} />
    </Box>
  );
};

const Title = ({ userRegion }) => (
  <ModalTitle variant="dark">
    Earn {userRegion === 'CAN' ? 'CPD' : 'CPE'} with engaging podcasts
  </ModalTitle>
);
