import { Box, Checkbox, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

import { Button, Select } from '@/components';

export const MultiSelectDropdown = ({
  selectedOptions,
  options,
  onSave,
  title,
}) => {
  const [opened, setOpened] = useState(false);
  const [temporarySelectedOptions, setTemporarySelectedOptions] = useState([]);

  useEffect(() => {
    setTemporarySelectedOptions(selectedOptions);
  }, [selectedOptions]);

  const onClose = () => {
    setTemporarySelectedOptions(selectedOptions);
    setOpened(false);
  };

  const onSavePress = () => {
    onSave(temporarySelectedOptions);
    setOpened(false);
  };

  const sortedEntries = Object.entries(options).sort((a, b) => {
    if (a[1].toLowerCase() < b[1].toLowerCase()) return -1;
    return 1;
  });

  const onMenuItemClick = value => {
    setTemporarySelectedOptions(prevSelectedOptions =>
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter(item => item !== value)
        : [...prevSelectedOptions, value],
    );
  };

  return (
    <Select
      selectedOptions={temporarySelectedOptions}
      isOpened={opened}
      onOpen={() => setOpened(true)}
      onClose={onClose}
      title={title}
      isMultiselect={true}>
      <Box sx={styles.body}>
        {sortedEntries.map(([key, value]) => (
          <MenuItem
            sx={styles.selectOption}
            key={key}
            value={key}
            onClick={() => onMenuItemClick(key)}>
            <Checkbox
              sx={styles.selectOptionCheckbox}
              checked={temporarySelectedOptions.indexOf(key) > -1}
            />
            {value}
          </MenuItem>
        ))}
      </Box>
      <Box sx={styles.applyFosButtonContainer}>
        <Button onClick={onSavePress} label="Save Selection" />
      </Box>
    </Select>
  );
};

const styles = {
  selectOption: {
    whiteSpace: 'unset',
    wordBreak: 'break-word',
  },
  selectOptionCheckbox: {
    '&.Mui-checked': {
      color: 'neutral500',
    },
  },
  applyFosButtonContainer: {
    position: 'sticky',
    bottom: 0,
    p: 1,
    width: '100%',
  },
  body: {
    py: 1,
    height: 360,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'block',
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#00000080',
      borderRadius: 1.25,
    },
  },
};
