import { Box, CircularProgress, Typography } from '@mui/material';

import { Button } from '@/components';
import { check, ticket, ticketDisabled } from '@/images';

export const RedeemModalContent = ({
  episode,
  plan,
  isRedeemRequired,
  isRedeeming,
  isB2CUser,
  onRedeemEpisode,
  onBuySubscription,
  closeModal,
}) => {
  if (!episode) {
    return (
      <Box sx={styles.container}>
        <CircularProgress sx={{ my: 10 }} />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {isRedeemRequired ? (
        <>
          {plan.creditsRemaining > 0 && (
            <img src={ticket} alt="" style={{ maxWidth: 114 }} />
          )}
          {plan.creditsRemaining <= 0 && (
            <img src={ticketDisabled} alt="" style={{ maxWidth: 114 }} />
          )}
          <Typography variant="h6" mt={4}>
            You have {plan.creditsRemaining} credits Available
          </Typography>
          <Typography variant="h6">
            {plan.creditsRemaining > episode.CPEDetails.credits &&
              `Use ${episode.CPEDetails.credits} Credits for:`}
            {plan.creditsRemaining < episode.CPEDetails.credits &&
              plan.creditsRemaining > 0 &&
              'Use Remaining Credits for:'}
            {plan.creditsRemaining <= 0 &&
              `You'll need ${episode.CPEDetails.credits} Credits for:`}
          </Typography>
          <Typography variant="h6" sx={{ my: 2, color: 'teal' }}>
            {episode.name}
          </Typography>
          <Box sx={{ width: '100%' }}>
            {isRedeeming ? (
              <CircularProgress />
            ) : plan.creditsRemaining > 0 ? (
              <Button
                label="Redeem"
                variant="primary"
                sx={{ mt: 1 }}
                onClick={onRedeemEpisode}
              />
            ) : (
              <Button
                variant="gradientPrimary"
                label={
                  isB2CUser ? 'Buy Subscription' : 'Message us to get credits'
                }
                sx={{ mt: 1 }}
                onClick={onBuySubscription}
              />
            )}
            <Button
              variant="tertiary"
              label="Cancel"
              onClick={closeModal}
              sx={{ mt: 1 }}
            />
          </Box>
        </>
      ) : (
        <>
          <img src={check} alt="" style={{ maxWidth: 114 }} />
          <Typography variant="body1" mt={4}>
            Episode Redeemed!
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            You have {plan.creditsRemaining} credits remaining
          </Typography>
        </>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    width: 350,
    maxHeight: 470,
    p: 3,
    pt: 4,
  },
};
