import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import {
  bookmarkEpisode,
  fetchUserEpisodesRequest,
  redeemEpisode,
  submitEpisodeEvaluation,
  submitEpisodeFeedback,
} from '@/requests';
import { nodeApi } from '@/store/node-api';
import { UserEpisode } from '@/types';

import { postgrestApi } from '../postgrest-api';
import { NodeApiTags, PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError } from '../store.utils';
import { userApiNode } from '../user-api';

type BookmarkEpisodePayload = {
  episodeId: string;
  bookmarked: boolean;
};

type SubmitEpisodeFeedbackPayload = {
  episodeId: string;
  feedback: any; // TODO: Define feedback type
  episodePlayedDuration: number;
};

type SubmitEpisodeEvaluationPayload = {
  episodeId: string;
  evaluation: any;
};

export const userEpisodesAdapter = createEntityAdapter<UserEpisode, string>({
  selectId: entity => entity.episodeId,
});

export const userEpisodesApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    fetchUserEpisodes: builder.query<
      EntityState<UserEpisode, string>,
      void | undefined
    >({
      query: fetchUserEpisodesRequest,
      transformResponse: (res: UserEpisode[]) =>
        userEpisodesAdapter.setAll(userEpisodesAdapter.getInitialState(), res),
      providesTags: [NodeApiTags.USER_EPISODES],
    }),
    redeemEpisode: builder.mutation<void, { episodeId: string }>({
      query: redeemEpisode,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            postgrestApi.util.invalidateTags([
              PostgrestApiTags.PLAN,
              PostgrestApiTags.USER_TAGS,
            ]),
          );
          dispatch(nodeApi.util.invalidateTags([NodeApiTags.USER_EPISODES]));
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    bookmarkEpisode: builder.mutation<void, BookmarkEpisodePayload>({
      query: bookmarkEpisode,
      invalidatesTags: [NodeApiTags.USER_EPISODES],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            userApiNode.endpoints.showProductFeedbackIfReady.initiate(null),
          );
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    submitEpisodeFeedback: builder.mutation<void, SubmitEpisodeFeedbackPayload>(
      {
        query: submitEpisodeFeedback,
      },
    ),
    submitEpisodeEvaluation: builder.mutation<
      void,
      SubmitEpisodeEvaluationPayload
    >({
      query: submitEpisodeEvaluation,
    }),
  }),
});

export const {
  useFetchUserEpisodesQuery,
  useRedeemEpisodeMutation,
  useBookmarkEpisodeMutation,
  useSubmitEpisodeFeedbackMutation,
  useSubmitEpisodeEvaluationMutation,
} = userEpisodesApi;
