import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import { Routes } from '@/constants';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import { createRouteWithParams } from '@/utils';

const ROW_MIN_HEIGHT = 96;

export const EpisodeRowDesktop = ({ episode, index, referrer }) => {
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `${ROW_MIN_HEIGHT * 3}px 0px ${ROW_MIN_HEIGHT * 3}px 0px`,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        AnalyticsService.episodeImpression({
          episode,
          location: {
            ...referrer,
            component: EVENT_CONSTANTS.COMPONENT.SEARCH_ROW,
          },
          referrer,
        });
      }
    },
  });

  const onEpisodeClick = () => {
    AnalyticsService.technicalSearchViewEpisode({
      episode,
      location: { ...referrer, position: index },
    });
    navigate(
      {
        pathname: createRouteWithParams(Routes.EPISODE, {
          episodeId: episode.episodeId,
        }),
      },
      {
        state: {
          referrer: {
            ...referrer,
            component: EVENT_CONSTANTS.COMPONENT.SEARCH_ROW,
          },
        },
      },
    );
  };

  const isOdd = index % 2 === 1;

  return (
    <Grid
      ref={ref}
      container
      spacing={0}
      sx={[styles.episodeRowContainer, isOdd && styles.grayBackground]}
      onClick={onEpisodeClick}>
      {inView && (
        <>
          <Grid item xs={8} style={{ display: 'flex' }}>
            <Box sx={styles.coverContainer}>
              <img src={`${episode.cover}?width=80`} alt="episode cover" />
            </Box>
            <Box sx={styles.textContainer}>
              <Tooltip title={episode.name} enterDelay={500}>
                <Typography sx={styles.titleText}>{episode.name}</Typography>
              </Tooltip>
              <Tooltip title={episode.hook} enterDelay={500}>
                <Typography sx={styles.hookText}>{episode.hook}</Typography>
              </Tooltip>
            </Box>
          </Grid>
          <Tooltip title={episode.subTypes} enterDelay={500}>
            <Grid item xs={2} sx={styles.gridItem}>
              <Box sx={styles.smallTextContainer}>
                <Typography sx={styles.miscText}>{episode.subTypes}</Typography>
              </Box>
            </Grid>
          </Tooltip>
          <Tooltip title={episode.regions} enterDelay={500}>
            <Grid item xs={2} sx={styles.gridItem}>
              <Box sx={styles.smallTextContainer}>
                <Typography sx={styles.miscText}>{episode.regions}</Typography>
              </Box>
            </Grid>
          </Tooltip>
        </>
      )}
    </Grid>
  );
};

const styles = {
  episodeRowContainer: {
    display: 'flex',
    p: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    minHeight: ROW_MIN_HEIGHT,
    '&:hover': {
      backgroundColor: 'grayBrightLight',
    },
  },
  grayBackground: {
    backgroundColor: 'grayNeutralLight',
  },
  coverContainer: {
    flexShrink: 0,
    height: 64,
    width: 64,
    borderRadius: 1.5,
    overflow: 'hidden',
    mr: 2,
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  smallTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  titleText: theme => ({
    ...theme.typography.subtitle2,
    color: 'blueBlack',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
  }),
  hookText: theme => ({
    ...theme.typography.subtitle2,
    color: 'gray',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
  }),
  miscText: theme => ({
    ...theme.typography.subtitle2,
    color: 'blueBlack',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  gridItem: {
    px: 0.75,
  },
};
