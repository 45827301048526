import { ChevronRight, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button as MuiButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthInfo, Button } from '@/components';
import { Routes, urls } from '@/constants';
import { Link } from '@/containers';
import { googleLogo } from '@/images';
import { useRegisterMutation } from '@/store';
import { getOauthURL } from '@/utils';

import { LoginRedirectModal } from './components';
import { PASSWORD_REQUREMENT_TEXT, RegisterErrorTypes } from './constants';
import { FormConfig } from './utils';

export const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef();

  const [form, setForm] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    email: null,
    password: null,
    confirmPassword: null,
  });
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isInvite = searchParams.get('inviteToken');
    const dismissed = location.hash === '#dismissed';
    if (isInvite && !dismissed) {
      setShowRedirectModal(true);
    }
  }, [location]);

  const [register, { isLoading }] = useRegisterMutation();

  const onChange = ({ target: { id, value } }) => {
    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  const onSubmit = async event => {
    event.preventDefault();

    const newErrors = {};
    Object.keys(FormConfig).forEach(id => {
      if (!FormConfig[id].validator(form)) {
        newErrors[id] = FormConfig[id].errorMessage;
      }
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    const { offerId, inviteToken } = Object.fromEntries(
      new URLSearchParams(location.search),
    );

    const { error: requestError } = await register({
      email: form.email.trim(),
      password: form.password.trim(),
      offerId,
      inviteToken,
      searchParams: Object.fromEntries(new URLSearchParams(location.search)),
    });

    if (requestError) {
      const errorMessage = requestError?.data?.message;
      const errorType = RegisterErrorTypes.find(
        item => item.response === errorMessage,
      );
      if (errorType) {
        setErrors({ [errorType.field]: errorType.message });
      } else {
        setErrors({
          password: 'Something went wrong. Please try again later.',
        });
      }
      return;
    }

    /*
      No redirect needed here because this is an anonymous-only route
      and AuthSessionLoader will trigger the redirect on auth state change
    */
  };

  const googleOauthURL = getOauthURL('google');

  return (
    <form onSubmit={onSubmit}>
      <Box sx={styles.pageContainer}>
        <AuthInfo inputRef={inputRef} />
        <Box sx={styles.pageSection} ref={inputRef}>
          <Typography variant="h1">Create An Account</Typography>
          <Box sx={styles.textFieldContainer}>
            <Typography variant="body3">Company Email</Typography>
            <TextField
              id="email"
              onChange={onChange}
              error={!!errors.email}
              fullWidth
            />
            {errors.email && (
              <Typography color="red" variant="body3">
                {errors.email}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              sx={[
                styles.textFieldContainer,
                styles.passwordContainer,
                { mr: 2 },
              ]}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body3">Password</Typography>
                <Tooltip
                  title={PASSWORD_REQUREMENT_TEXT}
                  placement="right"
                  disableInteractive>
                  <InfoOutlined sx={styles.tooltipIcon} />
                </Tooltip>
              </Box>
              <TextField
                type="password"
                id="password"
                onChange={onChange}
                error={!!errors.password}
                fullWidth
              />
            </Box>
            <Box sx={[styles.textFieldContainer, styles.passwordContainer]}>
              <Typography variant="body3">Confirm Password</Typography>
              <TextField
                type="password"
                id="confirmPassword"
                onChange={onChange}
                error={!!errors.confirmPassword}
                fullWidth
              />
            </Box>
          </Box>
          {(errors.password || errors.confirmPassword) && (
            <Typography color="red" variant="body3">
              {errors.password || errors.confirmPassword}
            </Typography>
          )}
          <Box sx={{ mt: 3 }}>
            <Typography component="span">
              By clicking "Agree and Continue", you agree to LumiQ's{' '}
            </Typography>
            <Link inline to={urls.lumiqTC}>
              Terms & Conditions
            </Link>{' '}
            <Typography component="span">and acknowledge our </Typography>
            <Link inline to={urls.lumiqPrivacy}>
              Privacy Policy
            </Link>
            <Typography component="span">.</Typography>
          </Box>
          <Box sx={styles.submitContainer}>
            <Button
              disabled={isLoading}
              label="Agree and Continue"
              skinny={false}
              type="submit"
              sx={{ flex: 1 }}
            />
          </Box>
          <Typography variant="h1" sx={{ mt: 4 }}>
            Or Sign Up With
          </Typography>
          <MuiButton href={googleOauthURL} sx={styles.oauthButton}>
            <Box
              component="img"
              alt="google logo"
              src={googleLogo}
              sx={styles.oauthIcon}
            />
            <Typography variant="button1">Google</Typography>
            <ChevronRight sx={{ ml: 'auto' }} />
          </MuiButton>
          <Box sx={styles.loginLinkContainer}>
            <Typography sx={{ textWrap: 'nowrap' }}>
              Already have an account?
            </Typography>
            <Link
              to={{ pathname: Routes.LOGIN, search: location.search }}
              inline>
              <Typography variant="body3" sx={{ textWrap: 'nowrap' }}>
                Login Here &gt;
              </Typography>
            </Link>
          </Box>
        </Box>
        <LoginRedirectModal
          isOpen={showRedirectModal}
          onClose={() => {
            setShowRedirectModal(false);
            navigate(
              {
                pathname: location.pathname,
                search: location.search,
                hash: '#dismissed',
              },
              { replace: true },
            );
          }}
        />
      </Box>
    </form>
  );
};

const styles = {
  pageContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  pageSection: {
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: 4,
      sm: 8,
    },
    flex: 1,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: 2,
  },
  passwordContainer: {
    flex: 1,
  },
  submitContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    mt: 3,
  },
  oauthButton: {
    mt: 2,
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'blueBlack',
    border: theme => `1px solid ${theme.palette.blueBlack}`,
    p: 2,
  },
  oauthIcon: {
    height: 30,
    mr: 2,
  },
  loginLinkContainer: {
    mt: 4,
    flex: 1,
  },
  tooltipIcon: {
    fontSize: 16,
    ml: 1,
    '&:hover': {
      opacity: 0.6,
    },
  },
};
