import { Close } from '@mui/icons-material';
import { Box, Dialog, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes, urls } from '@/constants';
import { Link } from '@/containers';

export const LoginRedirectModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 4,
        }}>
        <Close
          sx={{
            cursor: 'pointer',
            color: 'gray',
            ml: 'auto',
            '&:hover': { color: 'black' },
          }}
          onClick={onClose}
        />
        <Typography variant="h2">Have You Used LumiQ before?</Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Log in to accept your pending invite.
        </Typography>
        <Button
          label="Login"
          skinny={false}
          onClick={() =>
            navigate({
              pathname: Routes.LOGIN,
              search: location.search,
            })
          }
          sx={{ mt: 2 }}
        />
        <Typography variant="h2" sx={{ mt: 4 }}>
          Why Are We Asking?
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, textAlign: 'center' }}>
          We want to make sure you can always access your records, and that they
          fully belong to you! By keeping all listening records under one
          account, you can fully own, track, and manage your compliance.
        </Typography>
        <Button
          label="Continue to Register"
          variant="secondary"
          skinny={false}
          onClick={onClose}
          sx={{ mt: 2 }}
        />
        <Box sx={{ mt: 4 }}>
          <Typography variant="select" component="span">
            Need help? Reach out to{' '}
          </Typography>
          <Link inline to={`mailto:${urls.supportEmail}`}>
            <Typography variant="select" component="span">
              support
            </Typography>
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
