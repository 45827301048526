import { Button as MuiButton } from '@mui/material';

export const Variants = {
  GRADIENT_PRIMARY: 'gradientPrimary',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const Button = ({
  onClick,
  label,
  variant = 'primary',
  startIcon = null,
  disabled,
  fullWidth = true,
  skinny = true,
  background = 'light',
  sx,
  fileUploadHandler,
  ...props
}) => (
  <MuiButton
    onClick={onClick}
    sx={[
      sx,
      ({ typography }) => (skinny ? typography.button3 : typography.button2),
      {
        py: 1.5,
        px: 1.5,
        borderRadius: 6,
        textWrap: 'noWrap',
        textTransform: 'none',
        overflow: 'hidden',

        height: 32,
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
        ...(label && startIcon ? { pl: 1.5, pr: 2 } : {}),
        transition:
          'background-color 0.2s, opacity 0.2s, color 0.2s, border-color 0.2s',
        '& .MuiButton-startIcon': {
          mr: 1,
          ml: 0,
        },
      },
      styles[variant],
      background === 'dark' && {
        color: 'white',
        borderColor: 'white',
        '&:hover': {
          color: 'white',
          borderColor: 'white',
        },
      },
      !fullWidth && { minWidth: 'fit-content' },
      !skinny && {
        height: 48,
        '& .MuiSvgIcon-root': {
          fontSize: 24,
        },
        px: 2,
        ...(label && startIcon ? { pl: 2, pr: 3 } : {}),
      },
      skinny && {
        height: 32,
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
        px: 1.5,
        ...(label && startIcon ? { pl: 1.5, pr: 2 } : {}),
      },
      !label && {
        minWidth: 48,
        px: 0,
        '& .MuiButton-startIcon': {
          mr: 0,
        },
      },
    ]}
    variant={variant === Variants.SECONDARY ? 'outlined' : 'contained'}
    startIcon={startIcon}
    disableRipple
    disableElevation
    disabled={disabled}
    fullWidth={fullWidth}
    {...(fileUploadHandler && { component: 'label' })}
    {...props}>
    {label}
    {fileUploadHandler && (
      <input hidden type="file" onChange={fileUploadHandler} />
    )}
  </MuiButton>
);

const styles = {
  [Variants.PRIMARY]: ({ palette }) => ({
    backgroundColor: 'blue500',
    color: 'white',
    '&:hover': {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), ${palette.blue500}`,
    },
    '&:active': {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), ${palette.blue500}`,
      color: 'white',
    },
    '&:disabled': {
      backgroundColor: 'neutral35',
    },
  }),
  [Variants.GRADIENT_PRIMARY]: ({ palette, gradients, customShadows }) => ({
    boxShadow: customShadows.button,
    background: gradients.orange,
    color: 'white',
    '&:hover': {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), ${gradients.orange}`,
      boxShadow: customShadows.button,
    },
    '&:active': {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), ${gradients.orange}`,
      boxShadow: customShadows.button,
      color: 'white',
    },
    '&:disabled': {
      background: palette.neutral35,
      boxShadow: customShadows.button,
    },
  }),
  [Variants.SECONDARY]: {
    backgroundColor: 'transparent',
    color: 'text.black',
    borderColor: 'text.black',
    borderWidth: 1.5,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      color: 'text.black',
      borderColor: 'text.black',
      borderWidth: 1.5,
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&:disabled': {
      backgroundColor: 'neutral35',
    },
  },
  [Variants.TERTIARY]: {
    backgroundColor: 'transparent',
    color: 'text.black',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: 'neutral35',
    },
  },
};
