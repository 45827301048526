import { Box, Fade, Typography } from '@mui/material';
import { useState } from 'react';
import { InView, useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { DurationPill, EthicsPill, PremiumPill } from '@/components';
import { Routes } from '@/constants';
import { EpisodeCardSkeleton, Link, LinkVariants } from '@/containers';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  getIsEpisodeEthics,
  selectEpisode,
  selectIsFreeTrialUser,
  selectUserRegion,
} from '@/store';
import { createRouteWithParams } from '@/utils';

import { EpisodeToolbar } from './components';

export const EpisodeCard = ({ episodeId, adjustWidth = true, referrer }) => {
  const userRegion = useSelector(selectUserRegion);
  const episode = useSelector(state => selectEpisode(state, episodeId));
  const isFreeTrialUser = useSelector(selectIsFreeTrialUser);

  const [coverLoaded, setCoverLoaded] = useState(false);

  const location = {
    ...referrer,
    component: EVENT_CONSTANTS.COMPONENT.EPISODE_CARD,
  };

  const { ref: impressionRef } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        AnalyticsService.episodeImpression({
          episode,
          location,
          referrer,
        });
      }
    },
  });

  const isEpisodeEthics = getIsEpisodeEthics({ episode, userRegion });

  return (
    <>
      {!episode ? (
        <EpisodeCardSkeleton includeText={true} />
      ) : (
        <Box
          ref={impressionRef}
          sx={[styles.root, adjustWidth && styles.cardWidthAdjust]}>
          <Link
            to={{
              pathname: createRouteWithParams(Routes.EPISODE, {
                episodeId: episode.episodeId,
              }),
            }}
            state={{
              referrer: location,
            }}>
            <InView
              rootMargin="1000px 0px 1000px 0px"
              threshold={0}
              triggerOnce>
              {({ inView, ref: coverRef }) => (
                <Box sx={styles.cover} ref={coverRef}>
                  {inView && (
                    <>
                      <Fade in={coverLoaded} timeout={500}>
                        <Box
                          component="img"
                          id="episode-card-img"
                          sx={[
                            styles.coverImg,
                            { display: coverLoaded ? 'block' : 'none' },
                          ]}
                          src={episode.cover}
                          alt="cover"
                          onLoad={() => setCoverLoaded(true)}
                        />
                      </Fade>
                      <Box sx={{ position: 'absolute', top: 8, left: 8 }}>
                        {isEpisodeEthics && (
                          <EthicsPill
                            region={userRegion}
                            ethicsDuration={episode.totalEthics}
                          />
                        )}
                        {episode.isPremium && isFreeTrialUser && (
                          <PremiumPill />
                        )}
                      </Box>
                      <Box
                        component="img"
                        sx={[
                          styles.companyLogoImg,
                          { display: coverLoaded ? 'block' : 'none' },
                        ]}
                        src={episode.companyLogo}
                        alt="company logo"
                      />
                    </>
                  )}

                  {!coverLoaded && <EpisodeCardSkeleton />}
                </Box>
              )}
            </InView>
          </Link>
          <Box mb={1}>
            <EpisodeToolbar episodeId={episode.episodeId} referrer={location} />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Link
              variant={LinkVariants.PLAIN}
              to={{
                pathname: createRouteWithParams(Routes.EPISODE, {
                  episodeId: episode.episodeId,
                }),
              }}
              state={{ referrer: location }}>
              <Box id="episode-card-text" sx={styles.titleContainer}>
                <Box sx={{ mb: 0.5 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      cursor: 'pointer',
                    }}>
                    {episode.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mb: 1,
                  }}>
                  <Typography
                    variant="body3"
                    sx={{
                      cursor: 'pointer',
                      color: 'neutral500',
                    }}>
                    {episode.hook}
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
          <DurationPill duration={episode.duration} />
        </Box>
      )}
    </>
  );
};

const styles = {
  root: theme => ({
    display: 'flex',
    flexDirection: 'column',
    width: theme.dimensions.episodeCardCover.width,
    minHeight:
      theme.dimensions.episodeCardCover.height +
      theme.dimensions.episodeCardText.height,
    '&:has(#episode-card-text:hover) #episode-card-img': {
      opacity: 0.8,
    },
  }),
  cover: {
    position: 'relative',
    overflow: 'hidden',
    height: ({ dimensions }) => dimensions.episodeCardCover.height,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coverImg: {
    zIndex: 0,
    '&:hover': {
      opacity: 0.8,
    },
    cursor: 'pointer',
    height: '100%',
  },
  companyLogoImg: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    borderRadius: 1.5,
    height: 48,
    width: 48,
    pointerEvents: 'none',
  },
  titleContainer: {
    '& > div': {
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
  },
  cardWidthAdjust: theme => ({
    [theme.breakpoints.down('sm')]: {
      width: `min(${theme.dimensions.episodeCardCover.width}px, 50vw - 50px)`,
    },
  }),
};
