import * as R from 'ramda';

import {
  BmuCpaYearForm,
  CanCpaProvincesForm,
  CanCpaYearForm,
  DesignationTypesForm,
  Disclaimer,
  EmailForm,
  EmailSent,
  Introduction,
  IsDesignatedForm,
  JobDetailsForm,
  NameForm,
  NonDesignatedRegion,
  RecommendedEpisodes,
  UsaCpaStatesForm,
  UsaEaNumberForm,
  Welcome,
} from './components';

export const STEP_IDS = {
  introduction: 'introduction',
  isDesignated: 'isDesignated',
  nonDesignatedRegion: 'nonDesignatedRegion',
  designationTypes: 'designationTypes',
  disclaimer: 'disclaimer',
  canCpaProvinces: 'canCpaProvinces',
  canCpaYear: 'canCpaYear',
  usaCpaStates: 'cpaUsaStates',
  usaEaNumber: 'usaEaNumber',
  bmuCpaYear: 'bmuCpaYear',
  name: 'name',
  email: 'email',
  emailSent: 'emailSent',
  b2cJobDetails: 'b2cJobDetails',
  welcome: 'welcome',
  recommendedEpisodes: 'recommendedEpisodes',
};

export const STEPS = [
  {
    id: STEP_IDS.introduction,
    isRequired: () => true,
    handlePrev: null,
    handleNext: form => form,
    Component: Introduction,
  },
  {
    id: STEP_IDS.isDesignated,
    isRequired: () => true,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      userCountry: input ? undefined : form.userCountry,
      designations: input ? {} : undefined,
    }),
    Component: IsDesignatedForm,
  },
  {
    id: STEP_IDS.nonDesignatedRegion,
    isRequired: form => !form.designations,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      userCountry: input,
    }),
    Component: NonDesignatedRegion,
  },
  {
    id: STEP_IDS.designationTypes,
    isRequired: form => !!form.designations,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      designations: input,
    }),
    Component: DesignationTypesForm,
  },
  {
    id: STEP_IDS.disclaimer,
    isRequired: (form, { disclaimerDesignationOptions }) =>
      isDisclaimerRequired(form, disclaimerDesignationOptions),
    handlePrev: form => form,
    handleNext: form => form,
    Component: Disclaimer,
  },
  {
    id: STEP_IDS.canCpaProvinces,
    isRequired: form => !!form.designations?.CAN?.CPA,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      designations: {
        ...form.designations,
        CAN: {
          ...form.designations.CAN,
          CPA: {
            ...form.designations.CAN.CPA,
            provinces: input,
          },
        },
      },
    }),
    Component: CanCpaProvincesForm,
  },
  {
    id: STEP_IDS.canCpaYear,
    isRequired: form => !!form.designations?.CAN?.CPA,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      designations: {
        ...form.designations,
        CAN: {
          ...form.designations.CAN,
          CPA: {
            ...form.designations.CAN.CPA,
            year: input,
          },
        },
      },
    }),
    Component: CanCpaYearForm,
  },
  {
    id: STEP_IDS.usaCpaStates,
    isRequired: form => !!form.designations?.USA?.CPA,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      designations: {
        ...form.designations,
        USA: {
          ...form.designations.USA,
          CPA: input,
        },
      },
    }),
    Component: UsaCpaStatesForm,
  },
  {
    id: STEP_IDS.usaEaNumber,
    isRequired: form => !!form.designations?.USA?.EA,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      designations: {
        ...form.designations,
        USA: {
          ...form.designations.USA,
          EA: input,
        },
      },
    }),
    Component: UsaEaNumberForm,
  },
  {
    id: STEP_IDS.bmuCpaYear,
    isRequired: form => !!form.designations?.BMU?.CPA,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      designations: {
        ...form.designations,
        BMU: {
          ...form.designations.BMU,
          CPA: {
            ...form.designations.BMU.CPA,
            year: input,
          },
        },
      },
    }),
    Component: BmuCpaYearForm,
  },
  {
    id: STEP_IDS.name,
    isRequired: () => true,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      name: input,
    }),
    Component: NameForm,
  },
  {
    id: STEP_IDS.email,
    isRequired: (form, { isB2CUser }) => isB2CUser,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      email: input,
    }),
    Component: EmailForm,
  },
  {
    id: STEP_IDS.emailSent,
    isRequired: form => form.email,
    handlePrev: form => form,
    handleNext: form => form,
    Component: EmailSent,
  },
  {
    id: STEP_IDS.b2cJobDetails,
    isRequired: (form, { isB2CUser }) => isB2CUser,
    handlePrev: form => form,
    handleNext: (form, input) => ({
      ...form,
      jobDetails: input,
    }),
    Component: JobDetailsForm,
  },
  {
    id: STEP_IDS.welcome,
    isRequired: (form, { isRecommendedEpisodesExperiment }) =>
      !isRecommendedEpisodesExperiment,
    handlePrev: null,
    handleNext: form => form,
    Component: Welcome,
  },
  {
    id: STEP_IDS.recommendedEpisodes,
    isRequired: (form, { isRecommendedEpisodesExperiment }) =>
      isRecommendedEpisodesExperiment,
    handlePrev: null,
    handleNext: form => form,
    Component: RecommendedEpisodes,
  },
];

export const getSteps = (
  form,
  activeStepId,
  stateParams = {
    isB2CUser: false,
    disclaimerDesignationOptions: [],
  },
) =>
  STEPS.filter(
    step => step.id === activeStepId || step.isRequired(form, stateParams),
  );

export const getPrevStepId = ({ steps, activeStepId }) => {
  const activeStepIndex = steps.findIndex(step => step.id === activeStepId);
  return steps[activeStepIndex - 1]?.id || activeStepId;
};

export const getNextStepId = ({ steps, activeStepId }) => {
  const activeStepIndex = steps.findIndex(step => step.id === activeStepId);
  return steps[activeStepIndex + 1]?.id || activeStepId;
};

export const getDesignationYearOptions = () => {
  const currentYear = new Date().getFullYear();

  return {
    [currentYear]: currentYear,
    [currentYear - 1]: currentYear - 1,
    [currentYear - 2]: currentYear - 2,
    [currentYear - 3]: `${currentYear - 3} or before`,
  };
};

export const getDesignationCountry = form => {
  if (form.designations?.USA?.CPA) return 'USA';
  if (form.designations?.CAN?.CPA) return 'CAN';
  if (Object.values(form.designations?.USA || {}).length) return 'USA';
  if (Object.values(form.designations?.CAN || {}).length) return 'CAN';
  if (!form.designations) return form.userCountry;
  return 'CAN';
};

export const isDisclaimerRequired = (
  form,
  disclaimerDesignationOptions = [],
) => {
  const { designations } = form;
  return (
    R.filter(
      designation =>
        designations?.[designation.countryCode]?.[designation.designation],
    )(disclaimerDesignationOptions).length > 0
  );
};

export const getDisclaimerText = (form, disclaimerDesignationOptions = []) => {
  const { designations } = form;
  return R.pipe(
    R.filter(
      designation =>
        designations?.[designation.countryCode]?.[designation.designation],
    ),
    R.map(R.prop('disclaimer')),
  )(disclaimerDesignationOptions);
};
