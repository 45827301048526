import { CheckRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { Modals, urls } from '@/constants';
import { AnalyticsService, EVENTS } from '@/services';
import {
  getDisablePurchasing,
  selectSessionUserId,
  selectUserRegion,
  uiActions,
  useGetMyOffersQuery,
  useGetTwoForOneOfferQuery,
} from '@/store';
import { getRedirectUrl } from '@/utils';

import { PersonalOfferPlaceholder } from './PersonalOfferPlaceholder';
import { getActiveTwoForOneSkuId } from './utils';

export const PersonalOffer = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);
  const disablePurchasing = useSelector(getDisablePurchasing);
  const region = useSelector(selectUserRegion);

  const isTwoForOneActive = !!getActiveTwoForOneSkuId();

  const { data: userOffer, isLoading: isUserOffersLoading } =
    useGetMyOffersQuery(undefined, {
      skip: !userId,
    });

  const { data: twoForOneOffer, isLoading: isTwoForOneOfferLoading } =
    useGetTwoForOneOfferQuery(undefined, {
      skip: !isTwoForOneActive || region !== 'CAN',
    });

  const isOfferLoading = isUserOffersLoading || isTwoForOneOfferLoading;

  const offer = userOffer || twoForOneOffer;

  const onClickPurchase = () => {
    if (!userId) {
      window.open(getRedirectUrl(urls.accounts.login), '_self');
    } else {
      AnalyticsService.logEvent(EVENTS.PLAN_EVENTS.CLICK_BUY, {
        userId: userId,
        userOffer: offer,
      });
      dispatch(
        uiActions.setActiveModal({
          name: Modals.PURCHASE,
          params: { offer },
        }),
      );
    }
  };

  return isOfferLoading ? (
    <PersonalOfferPlaceholder />
  ) : !!offer ? (
    <Box>
      {offer.showBestValue && (
        <Box height={40} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              width: 205,
              bgcolor: 'blueBlack',
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography variant="h3" color="white">
              Best Value
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          maxWidth: { xs: 311, sm: 646 },
          borderRadius: 4,
          boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.25)',
          overflow: 'hidden',
          bgcolor: 'white',
        }}>
        <Box
          sx={{
            width: '100%',
            height: 48,
            background: theme =>
              theme.gradients[offer.top.background] || theme.gradients.primary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography variant="h3" color="white">
            {offer.top.title}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                m: 3,
              }}>
              <Typography
                variant="h1"
                sx={{
                  textDecoration: 'line-through',
                  color: 'redDark',
                }}>
                ${(offer.discount + offer.price).toFixed(2)}
              </Typography>
              <Typography variant="h1" color="blueBlack">
                ${offer.price.toFixed(2)}
              </Typography>
              <Box
                sx={{
                  p: '13px 15px',
                  bgcolor: 'grayLight',
                  borderRadius: 2,
                  border: theme => `1px solid ${theme.palette.grayBrightMid}`,
                  mt: 3,
                  maxWidth: 200,
                }}>
                <Typography variant="subtitle1">{offer.name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                m: 3,
              }}>
              <Typography variant="subtitle1" color="blueBlack">
                {offer.description}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  mt: 2,
                  gap: 1,
                }}>
                {offer.bullets?.map((bullet, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', flexDirection: 'row', gap: 0.25 }}>
                    <CheckRounded />
                    <Typography variant="select">{bullet}</Typography>
                  </Box>
                ))}
              </Box>
              <Box mt={3} width={200}>
                <Button
                  skinny={false}
                  label={offer.cta}
                  variant="primary"
                  onClick={onClickPurchase}
                  disabled={disablePurchasing}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};
