import { Box, Typography } from '@mui/material';

export const KeyTakeaways = ({ episode }) => {
  const { takeaways } = episode;
  return (
    <Box>
      <Typography variant="body2">
        After listening to this episode, you should be able to:
      </Typography>
      <ol>
        {takeaways?.map((takeaway, index) => (
          <li key={index}>
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {takeaway}
            </Typography>
          </li>
        ))}
      </ol>
    </Box>
  );
};
