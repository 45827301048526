import { CheckRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { Modals, urls } from '@/constants';
import { AnalyticsService, EVENTS } from '@/services';
import { getDisablePurchasing, selectSessionUserId, uiActions } from '@/store';
import { getRedirectUrl } from '@/utils';

export const Offer = ({ offer }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);
  const disablePurchasing = useSelector(getDisablePurchasing);

  const onClickPurchase = () => {
    if (offer.cta.toUpperCase() === 'CONTACT US') {
      window.Intercom?.('show');
    } else if (!userId) {
      window.open(getRedirectUrl(urls.accounts.login), '_self');
    } else {
      AnalyticsService.logEvent(EVENTS.PLAN_EVENTS.CLICK_BUY, {
        userId: userId,
        userOffer: offer,
      });
      dispatch(
        uiActions.setActiveModal({ name: Modals.PURCHASE, params: { offer } }),
      );
    }
  };

  return (
    <Box pb={5}>
      <Box height={40} sx={{ display: 'flex', justifyContent: 'center' }}>
        {offer.showBestValue && (
          <Box
            sx={{
              width: 205,
              bgcolor: 'blueBlack',
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography variant="h3" color="white">
              Best Value
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: 311,
          height: '100%',
          borderRadius: 4,
          boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.25)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'white',
          position: 'relative',
        }}>
        <Box
          sx={{
            height: 48,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: theme => theme.gradients[offer.top.background],
          }}>
          <Typography variant="h3" sx={{ color: 'white' }}>
            {offer.top.title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: 224,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 6,
            mb: 12,
            gap: 3,
          }}>
          {!!offer.price ? (
            <Typography variant="h1">${offer.price.toFixed(2)}</Typography>
          ) : (
            <Typography variant="h1" fontSize={36}>
              Custom Pricing
            </Typography>
          )}
          <Box
            sx={{
              p: '13px 15px',
              bgcolor: 'grayLight',
              borderRadius: 2,
              border: theme => `1px solid ${theme.palette.grayBrightMid}`,
            }}>
            <Typography variant="subtitle1">{offer.name}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '14px',
            }}>
            <Typography variant="subtitle2">{offer.description}</Typography>
            <Box sx={{ width: 108, height: '1px', bgcolor: 'blueBlack' }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                textAlign: 'left',
              }}>
              {offer.bullets.map((bullet, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', flexDirection: 'row', gap: 0.25 }}>
                  <CheckRounded />
                  <Typography key={bullet} variant="select">
                    {bullet}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 32,
            width: 200,
          }}>
          <Button
            variant="primary"
            skinny={false}
            label={offer.cta}
            onClick={onClickPurchase}
            disabled={disablePurchasing}
          />
        </Box>
      </Box>
    </Box>
  );
};
