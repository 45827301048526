export const NODE_BASE_URL = 'https://ipa.lumiqlearn.com/player';

export const NodeUnauthenticatedEndpoints = {
  EPISODE_RECENT_CHAPTER_ID: '/episode-recent-chapter-id',
  CHAPTER_LISTEN_ALLOWED: '/chapter-listen-allowed',
  CHAPTER_PROGRESS: '/chapter-progress',
  PLAYBACK_SPEED_OPTIONS: '/playback-speed-options',
} as const;

export const NodeAuthenticatedEndpoints = {
  USER_EPISODES: '/user-episodes',
  SAVE_CHAPTER_PROGRESS: '/save-chapter-progress',
  LAST_ACTIVE_CHAPTER: '/last-active-chapter',
  TRIENNIAL_SUMMARY: '/triennial-summary',
  USER_QUIZZES: '/quizzes',
  USER_REGION: '/user-region',
  UPDATE_USER_REGION: '/update-user-region',
  SUBMIT_QUIZ: '/submit-quiz',
  SUBMIT_ASSESSMENT: '/submit-assessment',
  REDEEM_EPISODE: '/redeem-episode',
  BOOKMARK_EPISODE: '/bookmark-episode',
  CREATE_CUSTOM_CPD_RECORD: '/create-custom-cpd-record',
  UPDATE_CUSTOM_CPD_RECORD: '/update-custom-cpd-record',
  DELETE_CUSTOM_CPD_RECORD: '/delete-custom-cpd-record',
  GET_CPD_CERTIFICATE: '/cpd-certificate',
  GET_CPD_CERTIFICATES: '/cpd-certificates',
  GET_CERTIFICATE_SUMMARY: '/cpd-summary-report',
  UPLOAD_CPD_RECORD_FILE: '/upload-cpd-record-file',
  SUBMIT_EPISODE_FEEDBACK: '/submit-episode-feedback',
  SUBMIT_EPISODE_EVALUATION: '/submit-episode-evaluation',
  PRODUCT_FEEDBACK_READY: '/product-feedback-ready',
  SUBMIT_PRODUCT_FEEDBACK: '/submit-product-feedback',
} as const;

export const NodeEndpoints = {
  ...NodeAuthenticatedEndpoints,
  ...NodeUnauthenticatedEndpoints,
} as const;
