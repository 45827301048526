import { createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';

import { EpisodeStatus, PlanTypes } from '@/constants';
import { UserEpisodeLegacy } from '@/types';

import { selectIsFsmPlayer } from '../player';
import { selectPlayerSessionsResult } from '../player-fsm-api';
import { RootState } from '../store';
import { selectPlan, selectUserRegion } from '../user-api';
import { userEpisodesAdapter, userEpisodesApi } from './user-episodes.slice';
import { pluckIdsOfStatus } from './user-episodes.utils';

export const selectUserEpisodesResult =
  userEpisodesApi.endpoints.fetchUserEpisodes.select(undefined);

export const selectUserEpisodes = createSelector(
  [selectUserEpisodesResult, selectPlayerSessionsResult, selectIsFsmPlayer],
  (userEpisodes, playerSessions, isFSMPlayerUser) =>
    isFSMPlayerUser ? playerSessions : userEpisodes,
);

export const selectUserEpisodesData = createSelector(
  [selectUserEpisodes],
  userEpisodesResult =>
    userEpisodesResult?.data ?? userEpisodesAdapter.getInitialState(),
);

export const userEpisodesSelectors = {
  ...userEpisodesAdapter.getSelectors(selectUserEpisodesData),
};

export const selectIsEpisodeRedeemRequired = createSelector(
  selectPlan,
  selectUserRegion,
  (state: RootState, episodeId: string) =>
    userEpisodesSelectors.selectById(state, episodeId),
  ({ data: plan }, region, userEpisode) => {
    if (region === 'CAN') return false;
    if (!plan) return true;
    if (plan.planType === PlanTypes.TIME) {
      return new Date(plan.validUntil) < new Date();
    }
    if (R.isNil(plan.creditsRemaining)) return false;
    if ((userEpisode as UserEpisodeLegacy)?.isRedeemed) return false;
    return true;
  },
);

export const selectInProgressEpisodeIds = createSelector(
  userEpisodesSelectors.selectAll,
  userEpisodes => pluckIdsOfStatus(EpisodeStatus.ON_GOING)(userEpisodes),
);

export const selectCompletedEpisodeIds = createSelector(
  userEpisodesSelectors.selectAll,
  userEpisodes => pluckIdsOfStatus(EpisodeStatus.VERIFIED)(userEpisodes),
);

export const selectAssessmentReadyEpisodeIds = createSelector(
  userEpisodesSelectors.selectAll,
  userEpisodes =>
    pluckIdsOfStatus(EpisodeStatus.ASSESSMENT_READY)(userEpisodes),
);
