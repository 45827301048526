import { GrowthBook } from '@growthbook/growthbook-react';
import mixpanel from 'mixpanel-browser';

import config from '@/config';
import { urls } from '@/constants';
import { Plan, Region, UserProfile } from '@/types';
import { ageInHours } from '@/utils';

import { AnalyticsService } from '../analytics';

export const growthbook = new GrowthBook({
  apiHost: urls.actions.growthbook,
  clientKey: config.GROWTHBOOK_CLIENT_CODE,
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback: (experiment, result) => {
    // TODO: May need to wire in to growthpanel init if distinct_id doesn't match
    mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    });
  },
});

// Note: growthbook will fail to load in test environments
if (process.env.NODE_ENV !== 'test') {
  growthbook.init({ streaming: true });
}

interface InitProps {
  userId?: string;
  userProfile?: UserProfile;
  plan?: Plan;
  userRegion?: Region;
  userTags?: string[];
  registrationState?: boolean;
}

export function initGrowthbook({
  userId,
  userProfile,
  plan,
  userRegion,
  userTags,
  registrationState,
}: InitProps) {
  growthbook.setAttributes({
    id: userId,
    email: userProfile?.email,
    organizationName: plan?.organizationName,
    region: userRegion,
    userTags,
    application: 'web-client',
    url: window.location.href,
    userAgent: navigator.userAgent,
    signupDate: userProfile?.userCreatedAt,
    signupAge: ageInHours(userProfile?.userCreatedAt),
    onboardingDate: userProfile?.onboardingCompletedAt,
    onboardingAge: ageInHours(userProfile?.onboardingCompletedAt),
    registrationState,
  });

  const features = Object.fromEntries(
    Object.keys(growthbook.getFeatures()).map(flag => [
      `$feature - ${flag}`,
      growthbook.getFeatureValue(flag, null),
    ]),
  );
  AnalyticsService.updateFeatureFlags(features);
}
