const isStringNotEmpty = string => string?.trim().length > 0;
const isEmailValid = form => isStringNotEmpty(form.email?.trim());
const isPasswordValid = form => isStringNotEmpty(form.password?.trim());

export const FormConfig = {
  email: {
    validator: isEmailValid,
    errorMessage: 'Please enter a valid email address.',
  },
  password: {
    validator: isPasswordValid,
    errorMessage: 'Please enter a valid password.',
  },
};
