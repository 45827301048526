import { emailRegex } from '@/constants';

const isEmailValid = form => emailRegex.test(form.email?.trim());
const isPasswordValid = form => form.password?.trim().length > 0;
const isConfirmPasswordValid = form =>
  form.confirmPassword?.trim() === form.password?.trim();

export const FormConfig = {
  email: {
    validator: isEmailValid,
    errorMessage: 'Please enter a valid email address.',
  },
  password: {
    validator: isPasswordValid,
    errorMessage: 'Please enter a valid password.',
  },
  confirmPassword: {
    validator: isConfirmPasswordValid,
    errorMessage: 'Passwords do not match.',
  },
};
