import { Routes, urls } from '@/constants';
import { log } from '@/services';

export const createRouteWithParams = (route, params) => {
  let result = route;
  Object.keys(params).forEach(key => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
};

export const getIsBranchLink = search => {
  return !!new URLSearchParams(search).get('_branch_match_id');
};

export const getUtmParameters = search => {
  return Array.from(new URLSearchParams(search).entries()).reduce(
    (obj, [param, value]) =>
      param.startsWith('utm_') ? { ...obj, [param]: value } : obj,
    {},
  );
};

// TODO: update this to use "redirect" when accounts pages are moved to web-client
export const getRedirectUrl = authUrl => {
  const { origin, pathname, search } = window.location;
  const searchParams = new URLSearchParams(search).toString();
  return `${authUrl}?${searchParams ? `${searchParams}&` : ''}luminariRedirect=${origin}${pathname}`;
};

export const parseRedirectUrl = () => {
  const searchParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  let redirectUrl;
  try {
    // Check for luminariRedirect for backwards compatibility
    redirectUrl = new URL(
      searchParams.redirect ||
        searchParams.luminariRedirect ||
        `${urls.appBase}${Routes.DISCOVER}`,
    );
  } catch (error) {
    // If URL is invalid, fall back to discover
    log(error);
    redirectUrl = new URL(`${urls.appBase}${Routes.DISCOVER}`);
  }

  delete searchParams.redirect;
  delete searchParams.luminariRedirect;

  const newSearchParams = {
    ...searchParams,
    ...Object.fromEntries(new URLSearchParams(redirectUrl.search)),
  };

  redirectUrl.search = new URLSearchParams(newSearchParams).toString();

  return redirectUrl.toString();
};

export const getOauthURL = service => {
  const redirectUrl = parseRedirectUrl();
  const params = { redirect: redirectUrl };

  return `${urls.auth[service]}?${new URLSearchParams(params).toString()}`;
};
