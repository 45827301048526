import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '@/config';
import { AppRouteWrapper } from '@/containers';
import { useCheckAccessTokenOnDocumentFocus } from '@/hooks';
import {
  AnalyticsService,
  initGrowthbook,
  initLogRocket,
  initSentry,
} from '@/services';
import {
  checkForExistingTokens,
  selectSessionIsLoading,
  selectSessionUserId,
} from '@/store';
import { parseRedirectUrl } from '@/utils';

export const AuthSessionLoader = () => {
  const dispatch = useDispatch();
  const sessionIsLoading = useSelector(selectSessionIsLoading);
  const userId = useSelector(selectSessionUserId);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(checkForExistingTokens());
  }, [dispatch]);

  useCheckAccessTokenOnDocumentFocus();

  useEffect(() => {
    window.Intercom('boot', {
      user_id: userId,
      app_id: config.INTERCOM_APP_ID,
      vertical_padding: 100,
      hide_default_launcher: isMobile,
    });
  }, [isMobile, userId]);

  useEffect(() => {
    // Initialize services
    initSentry({ userId });
    initGrowthbook({ userId });
    initLogRocket({ userId });
    AnalyticsService.initialize({ userId });

    // Redirect away if user is already authenticated
    if (userId) {
      const redirectUrl = parseRedirectUrl();
      window.location.replace(redirectUrl);
    }
  }, [userId]);

  return sessionIsLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <CircularProgress />
    </Box>
  ) : (
    <AppRouteWrapper />
  );
};
